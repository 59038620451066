import { FC, MutableRefObject, ReactNode, useRef, useState } from 'react'
import styles from './inputWrapper.module.css'
import useOutsideClick from '../../../hooks/useOutsideClick'
import classNames from 'classnames'

type InputWrapperProps = {
    label?: string
    error?: string
    endIcon?: string
    customHeight?: number
    enableResize?: boolean
    children?: ReactNode
    onClick?: () => void
    onClickOutside?: () => void
}

export const InputWrapper: FC<InputWrapperProps> = props => {
    const [inputFocused, setInputFocused] = useState(false)

    const ref = useRef() as MutableRefObject<HTMLInputElement>
    useOutsideClick([ref], () => {
        props.onClickOutside?.()
        setInputFocused(false)
    })

    const handleFocus = () => {
        props.onClick?.()
        if (!inputFocused) setInputFocused(true)
    }

    return (
        <>
            <div
                className={classNames(
                    styles.container,
                    inputFocused ? styles.focused : '',
                    props.error ? styles.error : '',
                    props.enableResize ? styles.resize : ''
                )}
                style={
                    props.customHeight
                        ? { minHeight: props.customHeight, height: '100%', alignItems: 'start' }
                        : {}
                }
                ref={ref}
                onClick={handleFocus}
            >
                {props.label && <label>{props.label}</label>}
                {props.children}
                {props.endIcon && (
                    <i className={classNames(`icon-${props.endIcon}`, styles.icon)} />
                )}
                {props.error && <p>{props.error}</p>}
            </div>
        </>
    )
}
