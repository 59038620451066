import { Mappings } from "../../models/mappings"
import styles from './mobileAdminCard.module.css'
import {FC} from 'react'
import {
    capitalizeFirstCharacter,
} from '../../utils/stringFormatter'
import classNames from 'classnames'
import { useNavigate } from "react-router-dom"

type MobileAdminCardProps = {
    userMapping: Mappings
}

export const MobileAdminCard: FC<MobileAdminCardProps> = props => {

    const navigate = useNavigate()

    return (
        <div 
            className={styles.card}
            onClick ={() => navigate('/admin/create')}
        >
            <div className={styles.rightSide}>
                <div className={styles.top}>
                    <i className="icon-user" />
                    <h5>{capitalizeFirstCharacter(props.userMapping.user_name)}</h5>
                </div>
            </div>



            <span className={styles.divider}></span>

            <div className={classNames(styles.mobileTable, styles.gray)}>
                <h4>Role</h4>
                <h3>{props.userMapping.role_name}</h3>
            </div>

            <div className={styles.mobileTable}>
                <h4>Rigs</h4>
                <div className={styles.wrapperNames}>
                    <h3>{props.userMapping.rig_names.join(', ')}</h3>
                </div>
                
            </div>
        </div>
    )
}