import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { LogEntry } from '../models/logEntry'
import API_PATHS from '../paths'

export const useLogEntryGetAll = (handlers?: {
    onSuccess?: (data: LogEntry[]) => void
    onError?: () => void
    disabled?: boolean
}) => {
    return useGet<LogEntry[]>({
        relativePath: API_PATHS.AllEntries,
        toastErrorText: 'Error retrieving log entries',
        ...handlers,
    })
}

export const useLogEntryCreate = (handlers?: { onSuccess?: () => void; onError?: () => void }) => {
    return usePost<LogEntry, unknown>({
        ...handlers,
        toastMessage: {
            error: 'Unable to create your log entry.',
            success: 'Your log entry has been created and saved',
        },
    })
}

export const useLogEntryEdit = (handlers: { onSuccess?: () => void; onError?: () => void }) => {
    return usePost<LogEntry, unknown>({
        ...handlers,
        toastMessage: {
            error: 'Unable to edit your log entry.',
            success: 'Your log entry has been edited and saved',
        },
    })
}
