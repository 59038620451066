import React, { useState } from 'react'
import { InputWrapper } from '../input-wrapper/inputWrapper'

type InputProps = {
    label?: string
    error?: string
    type?: string
    ref?: any
    value?: string
    change?: (value: string) => void 
}

export const Input = ({ label, error, type, value, change }: InputProps) => {
    const [userInput, setUserInput] = useState(value || '')

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
        setUserInput(newValue)
        if (change) {
            change(newValue) 
        }
    }

    return (
        <InputWrapper label={label} error={error}>
            <input type={type} value={userInput} onChange={handleChange} />
        </InputWrapper>
    )
}
