import { FC, useState } from 'react'
import styles from './timeSelectorModal.module.css'
import React from 'react'
import { ContextModal } from '../../common/context-modal/contextModal'
import useModal from '../../../hooks/contexts/useModal'

export const TIME_SELECTOR_MODAL_KEY = `time-selector-modal`

type TimeSelectorModalProps = {
    defaultTime?: [number, number]
    onSave: (time: [string, string]) => void
}

export const TIME_SELECTOR_KEY = `time-selector-modal-key`

export const TimeSelectorModal: FC<TimeSelectorModalProps> = props => {
    const { toggleModal } = useModal()

    const [[hours, minutes], setTime] = useState(
        props.defaultTime || [new Date().getHours(), new Date().getMinutes()]
    )

    const handleTimeChange = (field: 'hours' | 'minutes', isIncrement: boolean) => {
        if (field === 'hours') {
            if (isIncrement) setTime(prev => [(prev[0] + 1) % 24, prev[1]])
            else {
                const newHour = hours === 0 ? 23 : hours - 1
                setTime(prev => [newHour, prev[1]])
            }
        } else {
            if (isIncrement) setTime(prev => [prev[0], (prev[1] + 1) % 60])
            else {
                const newMinute = minutes === 0 ? 59 : minutes - 1
                setTime(prev => [prev[0], newMinute])
            }
        }
    }

    const handleSave = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation()
        const enhancedHours = hours < 10 ? `0${hours}` : `${hours}`
        const enhancedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
        toggleModal(TIME_SELECTOR_KEY)
        props.onSave([enhancedHours, enhancedMinutes])
    }

    return (
        <ContextModal modalKey={TIME_SELECTOR_KEY}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <div className={styles.chooserContainer}>
                    <div className={styles.simpleChooser}>
                        <i
                            className="icon-chevron-up"
                            onClick={() => handleTimeChange('hours', true)}
                        />
                        <span>{hours < 10 ? `0${hours}` : hours}</span>

                        <i
                            className="icon-chevron-down"
                            onClick={() => handleTimeChange('hours', false)}
                        />
                    </div>
                    <span>:</span>
                    <div className={styles.simpleChooser}>
                        <i
                            className="icon-chevron-up"
                            onClick={() => handleTimeChange('minutes', true)}
                        />
                        <span>{minutes < 10 ? `0${minutes}` : minutes}</span>
                        <i
                            className="icon-chevron-down"
                            onClick={() => handleTimeChange('minutes', false)}
                        />
                    </div>
                    <span>{hours < 12 ? 'AM' : 'PM'}</span>
                </div>
                <footer>
                    <span
                        onClick={e => {
                            toggleModal(TIME_SELECTOR_KEY)
                        }}
                    >
                        Cancel
                    </span>
                    <span onClick={handleSave}>Ok</span>
                </footer>
            </div>
        </ContextModal>
    )
}
