import { useEffect, useState } from 'react'
import { Toast } from '../components/common/toast/toast'
import { REACT_APP_API_URL } from '../utils/constants'
import { useMsal } from '@azure/msal-react'

type UseGetProps<T> = {
    relativePath: string
    toastErrorText: string
    disabled?: boolean
    onSuccess?: (data: T) => void | Promise<void>
    onError?: () => void
}

export function useGet<RequestResponse>(props: UseGetProps<RequestResponse>) {
    const { instance } = useMsal()
    const [isLoading, setIsLoading] = useState(false)
    const [refetchFlag, setRefetchFlag] = useState(false)
    const [data, setData] = useState<RequestResponse>()

    const refetch = () => {
        setRefetchFlag(prev => !prev)
    }

    useEffect(() => {
        const getData = async () => {
            if (!props.disabled) {
                setIsLoading(true)
                const { accessToken } = await instance.acquireTokenSilent({
                    scopes: ['user.read'],
                })
                if (accessToken) {
                    const requestOptions = {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        },
                    }
                    fetch(`${REACT_APP_API_URL}${props.relativePath}`, requestOptions)
                        .then(async response => {
                            const data: RequestResponse = await response.json()
                            setData(data)
                            props.onSuccess?.(data)
                        })
                        .catch(() => {
                            Toast({ type: 'error', message: props.toastErrorText })
                            props.onError?.()
                        })
                        .finally(() => {
                            setIsLoading(false)
                        })
                } else {
                    setIsLoading(false)
                }
            }
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.relativePath, refetchFlag, props.disabled])

    return { isLoading, data: data || [], refetch }
}
