import React, { FC } from 'react'
import styles from './radioCheck.module.css'

type RadioCheckProps = {
    isChecked: boolean
    onChange?: () => void
}

export const RadioCheck: FC<RadioCheckProps> = props => {
    return (
        <label className={styles.radio}>
            <input type="radio" name="radio" checked={props.isChecked} onChange={props.onChange} />
            <span></span>
        </label>
    )
}
