import { FC, ReactNode, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import useAuth from './hooks/contexts/useAuth'
import Header from './components/common/header/header'
import { useMsal } from '@azure/msal-react'
import { Toast } from './components/common/toast/toast'

const AuthGuard: FC<{
    children?: ReactNode
    hideHeader?: boolean
    extraAuth?: boolean
}> = props => {
    const { instance } = useMsal()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { setAuthenticatedAccount, isFetchingUserRoles, isAdmin, isManager, user } = useAuth()
    const extraAuth = props.extraAuth

    useEffect(() => {
        const activeAccount = instance.getActiveAccount()

        // If the user tries to access a route without log in, we send him to the login page
        if (!activeAccount) {
            instance.setActiveAccount(null)
            navigate('/', { state: pathname })
        }
        // If the user refresh the page, we just want to get his roles again
        else {
            setAuthenticatedAccount(activeAccount)
        }

        if (extraAuth && !!user && !isFetchingUserRoles){
            if(!isManager && !isAdmin){
                navigate('/log-entries',{state:pathname})
                Toast({type:'error',message:"You are not authorized to view that page."})
            } 
        }

    }, [pathname, navigate, instance, setAuthenticatedAccount, isAdmin, isManager, extraAuth, isFetchingUserRoles, user])

    return (
        <>
            {!props.hideHeader && <Header />}
            {props.children || <Outlet />}
        </>
    )
}

export default AuthGuard
