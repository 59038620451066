import { FC, useEffect, useState } from 'react'
import Button from '../common/button/button'
import styles from './mobileLogin.module.css'
import syzygyLogo from '../../assets/img/syzygyLogo.svg'
import classNames from 'classnames'

type MobileLoginProps = {
    isLoading: boolean
    onClick: () => void
}

export const MobileLogin: FC<MobileLoginProps> = props => {
    const [mobileStep, setMobileStep] = useState(0)

    useEffect(() => {
        if (mobileStep === 0) {
            setTimeout(() => setMobileStep(1), 2000)
        }
    })

    return (
        <>
            <div
                className={classNames(
                    styles.wrapper,
                    styles.blue,
                    mobileStep === 1 ? styles.hide : ''
                )}
            >
                <div className={styles.content}>
                    <div className={styles.logo}>
                        <figure>
                            <img src={syzygyLogo} alt="logo-syzygy" />
                        </figure>

                        <h3>Operator Log Book</h3>
                    </div>
                </div>
            </div>

            <div className={classNames(styles.wrapper, styles.white)}>
                <div className={styles.content}>
                    <div className={styles.card}>
                        <h3 className={styles.title}>Welcome!</h3>

                        <p>Log in to manage and record your operational activities.</p>

                        <Button
                            onClick={props.onClick}
                            variant="blue-bg"
                            text={props.isLoading ? 'Logging In' : 'Log In'}
                            isLoading={props.isLoading}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileLogin
