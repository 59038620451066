export const SET = (uoid) => {
    return {
        type:"SET",
        payload:uoid
    }
}

export const CLEAR = () => {
    return {
        type:"CLEAR",
        payload:"clear admin populate"
    }
}