import classNames from 'classnames'
import { LogEntry } from '../../models/logEntry'
import styles from './mobileEntryCard.module.css'
import { SHIFT_TYPE, TableHeader } from '../../utils/constants'
import {
    capitalizeOrReplace,
    formatDateString,
    capitalizeFirstCharacter,
    truncateHTML,
} from '../../utils/stringFormatter'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { htmlHasContent } from '../../utils/stringFormatter'

type MobileEntryCardProps = {
    entry: LogEntry
    columnsSelected: {
        name: TableHeader
        checked: boolean
    }[]
}

const getComponentColumnName = (entry: LogEntry, columnName: TableHeader) => {
    switch (columnName) {
        case 'Operators': {
            const hiddenOperators = entry.operators.length - 2
            const concatenatedOperators = `${entry.operators
                .map(op => op.name)
                .slice(0, 2)
                .join(', ')}${hiddenOperators > 0 ? `, and ${hiddenOperators} more...` : ''}`
            return (
                <div className={styles.wrapperNames}>
                    <h3>{concatenatedOperators}</h3>
                </div>
            )
        }
        case 'Files Attached':
            return (
                <div className={styles.files}>
                    <i className="icon-paperclip" />
                    <span>{entry.filenames?.length || 0}</span>
                </div>
            )
        case 'Shift Summary': {
            const summary = htmlHasContent(entry.shiftsummary)
                ? truncateHTML(20, entry.shiftsummary)
                : '-'
            return <div className={styles.summary}>{summary}</div>
        }

        case 'Pending Tasks': {
            const pendingTasks = htmlHasContent(entry.pendingtask)
                ? truncateHTML(20, entry.pendingtask)
                : '-'
            return <div className={styles.summary}>{pendingTasks}</div>
        }

        case 'Submitted Date':
            return <h3>{formatDateString(entry.submittedtime)}</h3>
        case 'Last Modified Date':
            return <h3>{formatDateString(entry.lastmodifiedtime)}</h3>
        case 'Last Modified By':
            return <h3>{capitalizeOrReplace('-', entry.lastmodifiedby?.name)}</h3>
        case 'Submitted By':
            return <h3>{capitalizeOrReplace('-', entry.submittedby?.name)}</h3>
        case 'Rig Name':
            return <h3>{capitalizeOrReplace('-', entry.rigname?.name)}</h3>
        case 'Shift Date':
            return <h3>{formatDateString(entry.shiftdate)}</h3>

        default: {
            return <></>
        }
    }
}

export const MobileEntryCard: FC<MobileEntryCardProps> = props => {
    const navigate = useNavigate()

    return (
        <div
            className={styles.card}
            onClick={() => navigate(`/entry-detail/${props.entry.internalId}`)}
        >
            <div className={styles.rightSide}>
                <div className={styles.top}>
                    <i className="icon-user" />
                    <h5>{`Submitted by ${capitalizeFirstCharacter(
                        props.entry.submittedby?.name,
                        '-'
                    )}`}</h5>
                </div>
                <div className={styles.bottom}>
                    <h6>{formatDateString(props.entry.submittedtime)}</h6>
                    <span
                        className={
                            props.entry.shifttype === SHIFT_TYPE.NIGHT ? styles.night : styles.day
                        }
                    >
                        {props.entry.shifttype === SHIFT_TYPE.NIGHT ? 'Night' : 'Day'}
                    </span>
                </div>
            </div>

            <span className={styles.divider} />

            {props.columnsSelected.map((column, index) => {
                if (column.checked) {
                    return (
                        <div
                            className={classNames(
                                styles.mobileTable,
                                index % 2 === 0 ? styles.gray : ''
                            )}
                            key={`${column}-${index}-${props.entry.id}`}
                        >
                            <h4>{column.name}</h4>
                            {getComponentColumnName(props.entry, column.name)}
                        </div>
                    )
                }
                return null
            })}
        </div>
    )
}

export default MobileEntryCard
