import { Routes, Route } from 'react-router-dom'
import AuthGuard from './AuthGuard'
import CreateLogEntryPage from './pages/CreateLogEntryPage'
import EditLogEntryPage from './pages/EditLogEntryPage'
import LoginPage from './pages/LoginPage'
import LogEntriesPage from './pages/LogEntriesPage'
import LogEntryDetailPage from './pages/LogEntryDetailPage'
import NotFoundPage from './pages/NotFoundPage'
import CreateAdminPage from './pages/CreateAdminPage'
import ViewAdminPage from './pages/ViewAdminPage'
import {Provider} from 'react-redux'
import CreateUserAdminPage from './pages/CreateUserAdminPage'

export const PageRouter = () => {


    return (
        <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route
                path="/log-entries"
                element={
                    <AuthGuard>
                        <LogEntriesPage />
                    </AuthGuard>
                }
            />
            <Route
                path="/create-entry"
                element={
                    <AuthGuard>
                        <CreateLogEntryPage />
                    </AuthGuard>
                }
            />
            <Route
                path="/edit-entry/:id"
                element={
                    <AuthGuard>
                        <EditLogEntryPage />
                    </AuthGuard>
                }
            />
            <Route
                path="/entry-detail/:id"
                element={
                    <AuthGuard>
                        <LogEntryDetailPage />
                    </AuthGuard>
                }
            />

            {/*  needs custom authguard for admin protection */}
            <Route
                path="/admin/create-user"
                element={
                    <AuthGuard extraAuth={true}>
                        <CreateUserAdminPage />
                    </AuthGuard>
                }
            />
            <Route
                path="/admin/create"
                element = {
                    <AuthGuard extraAuth={true}>
                        <CreateAdminPage/>
                    </AuthGuard>
                }
            />
                        <Route
                path = "/admin"
                element = {
                    <AuthGuard extraAuth={true}>
                        <ViewAdminPage/>
                    </AuthGuard>
                }
            />
            <Route
                path="*"
                element={
                    <AuthGuard>
                        <NotFoundPage />
                    </AuthGuard>
                }
            />
        </Routes>
    )
}
