import { Mappings } from '../../models/mappings'
import { ColumnDef,Row } from '@tanstack/react-table'
import {FC, useState, useMemo} from 'react'
import styles from './adminViewTable.module.css'
import Button from '../common/button/button'
import {Table } from '../common/table/table'
import { AdminViewModal } from '../modals/admin-view-modal/adminViewModal'

type AdminViewTableProps = {
    userMappings: Mappings[]
}

const OptionsCell: FC<{row: Row<Mappings>}> =props => {

    const [optionsOpened, setOptionsOpened] = useState(false)
    return(
        <div className={styles.options} id = {props.row.id}>
            <Button
                variant ={'transparent-bg'}
                startIconClassname="icon-more-vertical"
                onClick={e => {
                    e.stopPropagation()
                    setOptionsOpened(true)
                }}
            />
            {optionsOpened && (
                <AdminViewModal
                    uoid = {props.row.original.oid}
                    onClose={()=>setOptionsOpened(false)}
                    />
            )}
        </div>
    )
}

const COLUMNS_MAP: Record<string, ColumnDef<Mappings>> ={
    'User':{
        header:'User',
        accessorKey: 'user_name'
    },
    'Role':{
        header: 'Role',
        accessorKey:'role_name'
    },
    'Rigs':{
        header:'Rigs',
        enableSorting: false,
        accessorKey:'rig_name',
        cell: ({row}) => {
            const formattedOperatorList = row.original.rig_names.join(', ')
            return <>{formattedOperatorList}</>
        }
    },
    Options: {
        header: 'Options',
        cell: ({ row }) => <OptionsCell row={row} />,
    },
}

export const AdminViewTable: FC<AdminViewTableProps> = props => {

    const columns_to_show = ['User','Role','Rigs','Options']

    const columns = useMemo<ColumnDef<Mappings>[]>(
        () => columns_to_show.map(c => COLUMNS_MAP[c]),
        []
    )

    const mappedData: Mappings[] = props.userMappings

    return (
        <>
            <div className={styles.container}>
            <h3>Admin View</h3>
            </div>
            <Table data={mappedData} columns={columns}/>
        </>
    )
}