import {Layout} from '../components/common/layout/layout'
import {useEffect} from 'react'
import AdminForm from '../components/admin-form/AdminForm'
import useUsers from '../hooks/contexts/useUsers'
import useRigs from '../hooks/contexts/useRigs'
import { useAdminEntryUpdate } from '../services/adminEntry'
import { useNavigate } from 'react-router-dom'
import { useGetAllRoles } from '../services/roles'
import { Role } from '../models/role'
import { useSelector } from 'react-redux'

export const CreateAdminPage= () => {
    const navigate = useNavigate()

    const {users, isLoading: isLoadingUsers, enableFetchUsers} = useUsers()
    const { rigs, isLoading: isLoadingRigs, enableFetchRigs } = useRigs()
    const userRoles = useGetAllRoles().data
    const presetUoid = useSelector((state)=>state)
    userRoles.map((r:Role) => r.active=false)

    // custom method to post data using the update users route
    const {mutation} = useAdminEntryUpdate({
        onSuccess:()=> {
            navigate('/admin/create')
        },
        onError:()=>navigate('/admin/create')
    })

    useEffect(() => {
        enableFetchUsers()
        enableFetchRigs()
        // // eslint-disable-next-line react-hooks/exhaustive-deps\
    },[enableFetchRigs,enableFetchUsers]) 

    const initialLoading = isLoadingRigs || isLoadingUsers

    return (
        <Layout
        pageTitle="Create Admin Page"
        isLoading={initialLoading}
        >

        <AdminForm
            users={users||[]}
            roles={userRoles||[]}
            rigs={rigs||[]}
            oid={String(presetUoid)||''}
            updateUser={data => mutation(data)}
        />

        </Layout>
    )
}

export default CreateAdminPage
