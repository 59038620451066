import { Rig } from "../../models/rig"
import { User } from "../../models/user"
import {FC, useState, useEffect} from 'react'
import styles from './adminForm.module.css'
import { AdminEntry } from "../../models/adminEntry"
import { Select } from "../common/select/select"
import { SubmitHandler,useForm } from 'react-hook-form'
import Button from "../common/button/button"
import { MultipleSelectInput } from '../common/multiple-select-input/multipleSelectInput'
import { DEFAULT_ADMIN_ENTRY } from "../../utils/constants"
import API_PATHS from "../../paths"
import { REACT_APP_API_URL } from "../../utils/constants"
import useAuth from "../../hooks/contexts/useAuth"
import { Role } from "../../models/role"
import { UpdateUser } from "../../models/updateUser"
import { useMsal } from '@azure/msal-react'

type AdminFormProps = {
    entry?: AdminEntry
    rigs: Rig[]
    users: User[]
    roles: Role[]
    updateUser: SubmitHandler<UpdateUser>
    oid: string
}

export const AdminForm: FC<AdminFormProps> = props => {
    const {user:currentUser} = useAuth()
    const {
        register, 
        watch,
        handleSubmit,
        setValue,
        formState:{errors}
    } = useForm<AdminEntry>({defaultValues:props.entry||DEFAULT_ADMIN_ENTRY});
    // on form submission
    // gets rid of the "recent" tag on entries which are used to identify newly added rigs
    const updateUser =  (data: AdminEntry) => {
        
        const lastupdatedtime = new Date().toISOString()
        const lastupdatedby = currentUser?.oid
        const updatedRigs = data.rigs.filter((r:Rig) => ((!r?.recent && !r.active)||r.active))
        updatedRigs.map((r:Rig) => {
            delete(r.recent)
        })
        setValue('rigs',updatedRigs)
        const updatedRoles = data.userrole.filter((r:Role) => (!r?.recent && !r.active)||r.active)
        updatedRoles.map((r:Role) => {delete(r.recent)})
        setValue('userrole',updatedRoles)

        const updateUser = {
            oid: data.user?.oid,
            name: data.user?.name,

            roleids: updatedRoles,
            rigids: updatedRigs,

            lastupdatedby: lastupdatedby,
            lastupdatedtime: lastupdatedtime,
        }
        props.updateUser(updateUser)
    }
    const { instance } = useMsal()
    const [powerRole,setIsPowerRole] = useState(false)
    const [rigs, user, userrole] = watch(['rigs','user','userrole'])

    const autopopulateFields = async (selectedOid:string) => {
        // fetch clause needed as custom hooks not allowed within functions

        const { accessToken } = await instance.acquireTokenSilent({
            scopes: ['user.read'],
        })

        if(selectedOid){
            fetch(`${REACT_APP_API_URL}${API_PATHS.Users}/${selectedOid}`, {headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            }})
            .then(async response => {
                const selectedUser = await response.json()
                const userRole = selectedUser.roles
                const selectedRigs = selectedUser.rigs
                // the useState isManager is used to either clear or set to "ALL" for the rigs; used to determine selection validity
                const selectedUserRole= userRole?.filter((r:Role)=>r.active)[0]
                setIsPowerRole(selectedUserRole?.role==='manager'||selectedUserRole?.role==='admin')
                setValue('userrole',
                    userRole
                    ? userRole
                    : [])
                setValue('rigs',
                    selectedRigs
                    ? selectedRigs
                    : [])
                setValue('user',usersById[selectedOid])
            })
        }
    }

    useEffect(() => {
        autopopulateFields(props.oid)
    },[props.oid])

    const usersById: Record<string, User> = {}
    props.users.forEach(u => (usersById[u.oid] = u))

    const rigsById: Record<string, Rig> = {}
    props.rigs.forEach(rig => (rigsById[rig.oid] = rig))

    const rolesById: Record<string, Role> = {}
    props.roles.forEach(role => (rolesById[role.oid] = role))


    return (
        <>
            <div className={styles.container}>
                <h1>Update User</h1>
                <div className={styles.inputs}>
                    <form className={styles.form}>
                        <Select<User>
                            {
                                ...register('user',{
                                    required:'Select a user'
                                })}
                            options={props.users||[]}
                            ref={null}
                            renderOption={option => <>{option.name}</>}
                            label="User"
                            value={user?.name}
                            onChange={option => {
                                setValue('user',usersById[option.oid])
                                autopopulateFields(option.oid)
                            }
                            }
                            error={errors.user?.message}
                        />
                        <Select<Role>
                        {
                            ...register('userrole',{
                                required:'Choose a role'
                            })}
                            ref={null}
                            options={props.roles||[]}
                            renderOption={option => <>{option.role}</>}
                            label="Role"
                            value={userrole.filter((r:Role) => r.active)[0]?.role}
                            onChange={option => {
                                // tracks newly added roles
                                let roleExists = false
                                for (var r of userrole){
                                    if (r.oid!==option.oid){
                                        r.active=false
                                    }
                                    else{
                                        r.active=true
                                        roleExists = true
                                    }
                                }
                                if (!roleExists){
                                    userrole.push({...option, active:true, recent:true})
                                }
                                setIsPowerRole(option.role==='manager'||option.role==='admin')
                                // below either clears the rig entries for managers or autofills
                                if (option.role==='manager'){
                                    setValue('rigs',[{name: "ALL", oid: "be5c7c7a-67a1-4698-bdf1-2851d7e38569", active: true}])
                                }
                                if(option.role!=='manager'&&option.role!=='admin'&&powerRole){
                                    setValue('rigs',[])
                                }

                                setValue('userrole',userrole)
                            }}
                            error={errors.userrole?.message}
                        />
                        <MultipleSelectInput<Rig>
                            {
                                ...register('rigs',{
                                    required: 'Assign rigs',
                                    minLength:1
                            })}                       
                            options={props.rigs}
                            ref={null}
                            renderOption={option => <>{option.name}</>}
                            labelText="Rigs"
                            disabled={powerRole}
                            disablederror="Can't change rigs on Admins or Managers"
                            selected={rigs.filter((r:Rig)=> r.active)}
                            onChangeSelect={(rig: Rig) => {
                                const wasSelected = rigs
                                    ?.map((r: Rig) => r.active===true && r?.name)
                                    .includes(rig.name)
                                // instead of removing the rig from the array, it changes the "active" tag to false
                                if (wasSelected){
                                    rigs.map((r:Rig) => r.oid===rig.oid ? r.active=false : null)
                                }
                                else{
                                    // tracks newly added rigs
                                    let rigExists = false
                                    for (var r of rigs){
                                        if(r.oid===rig.oid){
                                            rigExists=true
                                            r.active = true
                                            break
                                        }
                                    }
                                    if (!rigExists){
                                        rigs.push({...rig, recent: true})
                                    }
                                }
                                setValue('rigs',rigs)
                            }}
                            noMatchesText="No Rigs Found"
                            error={errors.rigs?.message}
                        />
                    </form>
                </div>
            <footer>
                <Button 
                variant="blue-bg" 
                onClick={handleSubmit(updateUser)} text="Update Role"/>
            </footer>
        </div>
    </>
    )
}

export default AdminForm