import { Layout } from "../components/common/layout/layout"
import { useNavigate } from 'react-router-dom'
import { useGetAllMappings } from "../services/mappings"
import { AdminViewTable } from "../components/admin-view-table/AdminViewTable"
import { Mappings } from "../models/mappings"
import { AdminViewList } from "../components/admin-view-list/AdminViewList"
import { HeaderButton } from "../components/header-button/headerButton"

export const ViewAdminPage = () => {

    const navigate = useNavigate()

    // create API and get for all user mappings
    const userMappings = useGetAllMappings().data
    const appendedMappings: Mappings[] = []
    const mapUsers = new Set()
    for (var um of userMappings){
        if (mapUsers.has(um.user_name)){
            appendedMappings.filter(a => a.user_name==um.user_name)[0].rig_names.push(um.rig_name)
        }
        else{
            mapUsers.add(um.user_name)
            const tempM = um
            appendedMappings.push({
                'user_name':um['user_name'],
                'oid':um['oid'],
                'role_name':um['role_name'],
                'rig_names':[um['rig_name']],
            })
        }
    }

    const initialLoading = false
    return(
        <Layout
            pageTitle="View Admin Page"
            isLoading={initialLoading}
        >
            <HeaderButton onClick={() => navigate('/admin/create-user')} />

            <AdminViewList
                userMappings = {appendedMappings}
            />

        </Layout>
    )
}

export default ViewAdminPage