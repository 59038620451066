import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { useGetAllRigs } from '../../services/rig'
import { Rig } from '../../models/rig'
import useAuth from './useAuth'

export interface RigsContextValue {
    rigs?: Rig[]
    rigsById: Record<number, Rig>
    isLoading: boolean
    enableFetchRigs: () => void
}

export const RigsContext = createContext<RigsContextValue>({
    rigs: [],
    rigsById: {},
    isLoading: false,
    enableFetchRigs: () => {},
})

export const RigsProvider: FC<{
    children?: ReactNode
}> = props => {
    const { user } = useAuth()
    const [rigs, setRigs] = useState<Rig[]>()
    const [enabled, setEnabled] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [rigsById, setRigsById] = useState<Record<string, Rig>>({})

    const { isLoading: isFetching } = useGetAllRigs({
        disabled: !enabled || !user,
        onSuccess: setRigs,
        onError: () => {
            setIsLoading(false)
            setEnabled(false)
        },
    })

    useEffect(() => {
        if (rigs) {
            setIsLoading(true)
            const rigsMap: Record<string, Rig> = {}
            rigs.forEach(rig => {
                if (rig.oid) {
                    rigsMap[rig.oid] = rig
                }
            })
            setRigsById(rigsMap)
            setIsLoading(false)
        }
    }, [rigs])

    const enableFetch = () => {
        if (!enabled) {
            setEnabled(true)
            setIsLoading(true)
        }
    }

    return (
        <RigsContext.Provider
            value={{
                rigs: rigs,
                rigsById: rigsById,
                isLoading: isFetching || isLoading,
                enableFetchRigs: enableFetch,
            }}
        >
            {props.children}
        </RigsContext.Provider>
    )
}

export const useRigs = (): RigsContextValue => useContext(RigsContext)

export default useRigs
