import { useNavigate } from 'react-router-dom'
import TableFilters from '../components/table-filters/tableFilters'
import { useEffect, useState } from 'react'
import { LogEntry } from '../models/logEntry'
import {
    ColumnSelection,
    DEFAULT_COLUMNS_STATE,
    DEFAULT_SORT,
    SORT_BY_OPTIONS,
    TableHeader,
} from '../utils/constants'
import useLogEntry from '../hooks/contexts/useLogEntry'
import { HeaderButton } from '../components/header-button/headerButton'
import { Layout } from '../components/common/layout/layout'
import useAuth from '../hooks/contexts/useAuth'
import { getLogEntriesByUserRole } from '../utils/permission'
import { useWindowSize } from '../hooks/useWindowSize'
import { User } from '../models/user'
import LogEntriesList from '../components/log-entries-list/logEntriesList'
import { AgGrid } from '../components/ag-grid/agGrid'

const getSortingFunction = (firstEntry: LogEntry, secondEntry: LogEntry, sort: TableHeader) => {
    switch (sort) {
        case 'Rig Name':
            return firstEntry.rigname?.name.localeCompare(secondEntry.rigname?.name || '') || 1
        case 'Submitted By':
            return (
                firstEntry.submittedby?.name.localeCompare(secondEntry.submittedby?.name || '') || 1
            )
        case 'Last Modified Date':
            return (
                new Date(firstEntry.lastmodifiedtime).getTime() -
                new Date(secondEntry.lastmodifiedtime).getTime()
            )
        case 'Submitted Date':
            return (
                new Date(secondEntry.submittedtime).getTime()-
                new Date(firstEntry.submittedtime).getTime() 

            )
        case 'Shift Date':
            return (
                new Date(firstEntry.shiftdate).getTime() - new Date(secondEntry.shiftdate).getTime()
            )
        case 'Files Attached':
            return (secondEntry.filenames?.length || 0) - (firstEntry.filenames?.length || 0)
        default:
            return 1
    }
}

const getColumnsInitialState = () => {
    return localStorage.getItem('columnsSelected')
        ? JSON.parse(localStorage.getItem('columnsSelected')!)
        : DEFAULT_COLUMNS_STATE
}

const getPosibleSortingOptions = (columnsSelected: ColumnSelection) => {
    let options = SORT_BY_OPTIONS
    columnsSelected.forEach(column => {
        if (!column.checked) options = options.filter(option => option !== column.name)
    })
    return options
}

const filterAndOrderEntries = (
    allEntries: LogEntry[],
    searchInput: string,
    sortedBy: TableHeader,
    user?: User
) => {
    const lowerSearchInput = searchInput.toLowerCase()

    const userLogEntries = getLogEntriesByUserRole(allEntries, user)
    const filteredEntries = userLogEntries.filter(
        entry =>
            entry.rigname?.name.toLowerCase().includes(lowerSearchInput) ||
            entry.shiftsummary?.toLowerCase().includes(lowerSearchInput) ||
            entry.operators.some(operator =>
                operator.name.toLowerCase().includes(lowerSearchInput)
            ) ||
            entry.pendingtask?.toLowerCase().includes(lowerSearchInput) ||
            entry.shifttype?.toLowerCase().toLowerCase().includes(lowerSearchInput) ||
            entry.submittedby?.name.toLowerCase().includes(lowerSearchInput)
    )

    return filteredEntries.sort((a, b) => getSortingFunction(a, b, sortedBy))
}

export const LogEntriesPage = () => {
    const navigate = useNavigate()
    const { isMobileView } = useWindowSize()
    const { user, isFetchingUserRoles } = useAuth()

    const [searchInput, setSearchInput] = useState('')
    const [sortBy, setSortBy] = useState<TableHeader>(DEFAULT_SORT)
    const [columnsSelected, setColumnsSelected] = useState<ColumnSelection>(
        getColumnsInitialState()
    )

    const { isLoading, logEntries } = useLogEntry()

    useEffect(() => {
        localStorage.setItem('columnsSelected', JSON.stringify(columnsSelected))
    }, [columnsSelected])

    // Event handlers
    const handleModalCheckColumn = (columnName: string) =>
        setColumnsSelected(prev =>
            prev.map(column =>
                column.name === columnName ? { ...column, checked: !column.checked } : column
            )
        )

    const handleColumnsSwap = (dragIndex: number, hoverIndex: number) => {
        const hoverColumn = { ...columnsSelected[hoverIndex] }
        let columns = [...columnsSelected]
        columns[hoverIndex] = columns[dragIndex]
        columns[dragIndex] = hoverColumn
        setColumnsSelected(columns)
    }
    return (
        <Layout isLoading={!logEntries}>
            <HeaderButton onClick={() => navigate('/create-entry')} />

            {/* <AgGrid logEntries={logEntries}/> */}

            <TableFilters
                sortBy={{ options: getPosibleSortingOptions(columnsSelected), selected: sortBy }}
                setSortedBy={setSortBy}
                onChangeInput={setSearchInput}
                columnsSelected={columnsSelected}
                onSelectColumn={handleModalCheckColumn}
                onSwapColumns={handleColumnsSwap}
                onResetColumns={() => setColumnsSelected(DEFAULT_COLUMNS_STATE)}
            />

            <LogEntriesList
                logEntries={filterAndOrderEntries(logEntries || [], searchInput, sortBy, user)}
                columnsSelected={
                    isMobileView
                        ? columnsSelected
                        : [...columnsSelected, { name: 'Options', checked: true }]
                }
            />
        </Layout>
    )
}

export default LogEntriesPage
