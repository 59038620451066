import useLogEntry from '../hooks/contexts/useLogEntry'
import { useNavigate } from 'react-router-dom'
import { Layout } from '../components/common/layout/layout'
import LogEntryForm from '../components/log-entry-form/logEntryForm'
import { useLogEntryCreate } from '../services/logEntry'
import useAuth from '../hooks/contexts/useAuth'
import useRigs from '../hooks/contexts/useRigs'
import { useEffect } from 'react'
import useUsers from '../hooks/contexts/useUsers'

const CreateLogEntryPage = () => {
    const navigate = useNavigate()
    const { isFetchingUserRoles } = useAuth()

    const { refetch: refetchLogEntries } = useLogEntry()
    const { rigs, isLoading: isLoadingRigs, enableFetchRigs } = useRigs()
    const { users: operators, isLoading: isLoadingOperators, enableFetchUsers } = useUsers()

    useEffect(() => {
        enableFetchRigs()
        enableFetchUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { mutation } = useLogEntryCreate({
        onSuccess: () => {
            refetchLogEntries()
            navigate('/log-entries')
        },
        onError: () => navigate('/log-entries'),
    })

    const initialLoading = isLoadingRigs || isLoadingOperators || isFetchingUserRoles

    return (
        <Layout
            pageTitle="Create New Log Entry"
            navigationBackUrl="/log-entries"
            isLoading={initialLoading}
        >
            <LogEntryForm
                rigs={rigs || []}
                operators={operators || []}
                onSave={data => mutation(data)}
            />
        </Layout>
    )
}

export default CreateLogEntryPage
