import { FC } from 'react'
import { useDragAndDrop } from '../../../hooks/useDragAndDrop'
import Button from '../../common/button/button'
import { Checkbox } from '../../common/checkbox/checkbox'
import { ContextModal } from '../../common/context-modal/contextModal'
import styles from './tableColumnSelectorModal.module.css'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { TableHeader } from '../../../utils/constants'

export const TABLE_COLUMN_SELECTOR_MODAL_KEY = 'table-column-selector-modal'

type TableColumnsSelectorProps = {
    columns: {
        name: TableHeader
        checked: boolean
    }[]
    onChange: (labelChanged: string) => void
    onSwapRows?: (dragIndex: number, hoverIndex: number) => void
    onReset: () => void
}

const SwappableRow = (props: {
    column: {
        name: string
        checked: boolean
    }
    index: number
    onSwap?: (dragIndex: number, hoverIndex: number) => void
    onChange: (labelChanged: string) => void
}) => {
    const { drag, drop, ref } = useDragAndDrop(props.index, props.index, props.onSwap)

    drag(props.onSwap ? ref : null)
    drop(props.onSwap ? ref : null)

    return (
        <div ref={ref}>
            <li onClick={() => props.onChange(props.column.name)}>
                <div className={styles.labelContainer}>
                    <i className="icon-vertical-six-dots" />
                    <h5>{props.column.name}</h5>
                </div>
                <Checkbox
                    isChecked={props.column.checked}
                    onChange={() => props.onChange(props.column.name)}
                />
            </li>
        </div>
    )
}

export const TableColumnsSelectorModal: FC<TableColumnsSelectorProps> = props => {
    const { isMobileView } = useWindowSize()
    return (
        <ContextModal modalKey={TABLE_COLUMN_SELECTOR_MODAL_KEY} grayOverlay={isMobileView}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <h5>Fields Displayed</h5>
                    <Button
                        text="Reset View"
                        startIconClassname="icon-rotate-ccw"
                        variant="transparent-bg"
                        onClick={e => props.onReset()}
                    />
                </div>

                <ul>
                    {props.columns.map((column, index) => (
                        <SwappableRow
                            column={column}
                            index={index}
                            onChange={props.onChange}
                            onSwap={props.onSwapRows}
                            key={`swappable-row-${column.name}-${index}`}
                        />
                    ))}
                </ul>
            </div>
        </ContextModal>
    )
}
