import { EditorState } from 'draft-js'
import { Dispatch, FC, SetStateAction } from 'react'
import { Editor } from 'react-draft-wysiwyg'

type HtmlEditorProps = {
    editor: EditorState
    onEditorStateChange: Dispatch<SetStateAction<EditorState>>
}

export const HtmlEditor: FC<HtmlEditorProps> = props => {
    return (
        <Editor
            onEditorStateChange={props.onEditorStateChange}
            editorState={props.editor}
            toolbar={{
                options: ['fontFamily', 'blockType', 'inline', 'list'],
                inline: {
                    inDropdown: false,
                    options: ['bold', 'italic', 'underline', 'strikethrough'],
                },
                list: {
                    inDropdown: false,
                    options: ['unordered', 'ordered'],
                },
            }}
        />
    )
}
