import { FC, Fragment } from 'react'
import { Checkbox } from '../common/checkbox/checkbox'
import styles from './filesTable.module.css'
import { capitalizeOrReplace, formatFileSize } from '../../utils/stringFormatter'
import { DELETE_FILE_MODAL_KEY, DeleteFileModal } from '../modals/delete-file-modal/deleteFileModal'
import useModal from '../../hooks/contexts/useModal'
import { useWindowSize } from '../../hooks/useWindowSize'
import { FileUpload } from '../../models/files'
import { downloadFileFromAzure } from '../../services/fileUploading'
import Button from '../common/button/button'
import { Toast } from '../common/toast/toast'
import { useMsal } from '@azure/msal-react'

type FilesTableProps = {
    files: FileUpload[]
    isEditing?: boolean
    allFilesChecked?: boolean
    checkedFiles: string[]
    onCheckAllFiles?: () => void
    onCheckFile: (fileName: string) => void
    onDeleteFiles?: (selectedFilesName?: string[]) => void
}

export const FilesTable: FC<FilesTableProps> = props => {
    const { width } = useWindowSize()
    const { toggleModal } = useModal()
    const { instance } = useMsal()

    const isDesktopView = width && width > 1023

    return (
        <div className={styles.files}>
            <div className={styles.tableContainer}>
                <div className={`${styles.tableRow} ${styles.heading}`}>
                    <div className={`${styles.rowItem} ${styles.firstColumn}`}>
                        <Checkbox
                            isChecked={!!props.allFilesChecked}
                            onChange={props.onCheckAllFiles}
                        />
                    </div>
                    <div className={`${styles.rowItem} ${styles.secondColumn}`}>File Name</div>
                    <div className={`${styles.rowItem} ${styles.thirdColumn}`}>File Size</div>
                    <div className={`${styles.rowItem} ${styles.fourthColumn}`}>
                        File Description
                    </div>
                    <div className={styles.fifthColumn} />
                </div>
                {props.files?.map((file, index) => (
                    <Fragment key={`files-${file.original}-${index}`}>
                        {!isDesktopView && (
                            <DeleteFileModal
                                fileName={file.original}
                                onDelete={() => props.onDeleteFiles?.([file.original])}
                            />
                        )}
                        <div className={styles.tableRow}>
                            <div className={`${styles.rowItem} ${styles.firstColumn}`}>
                                <Checkbox
                                    isChecked={props.checkedFiles.includes(file.original)}
                                    onChange={() => props.onCheckFile(file.original)}
                                />
                            </div>
                            <div className={`${styles.rowItem} ${styles.secondColumn}`}>
                                {file.original}
                            </div>
                            <div className={`${styles.rowItem} ${styles.thirdColumn}`}>
                                {formatFileSize(file.size)}
                            </div>
                            <div className={`${styles.rowItem} ${styles.fourthColumn}`}>
                                {capitalizeOrReplace('No description', file.description)}
                            </div>

                            <div className={`${styles.rowItem} ${styles.lastColumn}`}>
                                {props.isEditing ? (
                                    <>
                                        <Button
                                            startIconClassname="icon-trash"
                                            variant="transparent-bg"
                                            onClick={() => {
                                                toggleModal(DELETE_FILE_MODAL_KEY(file.original))
                                            }}
                                        />
                                        {isDesktopView && (
                                            <DeleteFileModal
                                                fileName={file.original}
                                                onDelete={() =>
                                                    props.onDeleteFiles?.([file.original])
                                                }
                                            />
                                        )}
                                    </>
                                ) : (
                                    <Button
                                        startIconClassname="icon-download"
                                        variant="transparent-bg"
                                        onClick={async () => {
                                            const { accessToken } = await instance.acquireTokenSilent({
                                                scopes: ["user.read"],
                                            });
                                            Toast({
                                                type: 'info',
                                                message: 'Downloading your files',
                                            })
                                            await downloadFileFromAzure([
                                                { unique: file.unique!, fileName: file.original },
                                            ], accessToken)
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </Fragment>
                ))}
            </div>
        </div>
    )
}

export default FilesTable
