import { FC, ReactNode, createContext, useContext, useState } from 'react'

export type ModalKey = string // Unique key per modal instance

export interface ModalContextValue {
    modalKeys: ModalKey[]
    toggleModal: (modalKey: ModalKey) => void
    closeModal: (modalKey: ModalKey) => void
}

export const ModalContext = createContext<ModalContextValue>({
    modalKeys: [],
    toggleModal: () => {},
    closeModal: () => {},
})

export const ModalProvider: FC<{
    children?: ReactNode
}> = props => {
    const { children } = props
    const [modalKeys, setModalKeys] = useState<ModalKey[]>([])

    const toggleModal = (modalKey: ModalKey): void => {
        setModalKeys(keys =>
            keys.includes(modalKey) ? keys.filter(key => key !== modalKey) : [...keys, modalKey]
        )
    }

    const closeModal = (modalKey: ModalKey) => {
        setModalKeys(keys => keys.filter(key => key !== modalKey))
    }

    return (
        <ModalContext.Provider
            value={{
                modalKeys,
                toggleModal,
                closeModal,
            }}
        >
            {children}
        </ModalContext.Provider>
    )
}

export const ModalConsumer = ModalContext.Consumer

const useModal = (): ModalContextValue => useContext(ModalContext)

export default useModal
