import '../src/assets/css/styles.css'
import styles from './App.module.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PageRouter } from './PageRouter'
import adminReducer from './redux/adminReducer'
import {configureStore} from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
const App = () => {

    const adminStore = configureStore({
        reducer: adminReducer
    })
    
    return (
        <Provider store={adminStore}>
            <div className={styles.app}>
                <PageRouter />
                <ToastContainer />
            </div>
        </Provider>
    )
}

export default App
