const initialState = ''

const adminReducer = (state=initialState, action) => {
    switch(action.type){
        case "SET":
            return action.payload
        case "CLEAR":
            return {uoid:''}
        default: 
            return state
    }
}

export default adminReducer