import { FC } from 'react'
import { ContextModal } from '../../common/context-modal/contextModal'
import styles from './tableSortByModal.module.css'
import { RadioCheck } from '../../common/radio-check/radioCheck'
import useModal from '../../../hooks/contexts/useModal'
import { useWindowSize } from '../../../hooks/useWindowSize'

type TableSortByProps = {
    selected: string
    options: string[]
    onChange: (labelChanged: string) => void
}

export const TABLE_SORT_MODAL_KEY = 'table-sort-modal'

export const TableSortByModal: FC<TableSortByProps> = props => {
    const { toggleModal } = useModal()
    const { isMobileView } = useWindowSize()
    return (
        <ContextModal modalKey={TABLE_SORT_MODAL_KEY} grayOverlay={isMobileView}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <h5>Sort By</h5>
                    <span />
                </div>

                <ul>
                    {props.options.map(option => (
                        <li
                            onClick={() => {
                                props.onChange(option)
                                toggleModal(TABLE_SORT_MODAL_KEY)
                            }}
                            key={`sort-by-${option}`}
                        >
                            <div className={styles.labelContainer}>
                                <h5>{option}</h5>
                            </div>
                            <RadioCheck
                                isChecked={option === props.selected}
                                onChange={() => props.onChange(option)}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </ContextModal>
    )
}
