const API_PATHS = {
    AllEntries: '/all',
    EntriesCreate: '/',
    FileToken: '/filetoken',
    AllOperators: '/operators/all',
    AllRigs: '/rigs/all',
    AllUsers: '/users/all', 
    Users: '/users',
    AllRoles: '/roles/all',
    UpdateUsers: '/users/update',
    CreateUsers: '/users/create',
    OperatorsFromRig: '/users/rig',
    AllUserMappings: '/users/mappings'
}

export default API_PATHS
