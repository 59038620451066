import { ReactNode, useState } from 'react'
import styles from './multipleSelect.module.css'
import classnames from 'classnames'
import { InputWrapper } from '../input-wrapper/inputWrapper'
import { Toast } from '../../../components/common/toast/toast'

type MultipleSelectInputsProps<T> = {
    placeholder?: string
    options: T[]
    selected: T[]
    noMatchesText: string
    labelText: string
    disabled?: boolean
    error?: string
    disablederror?:string
    ref?: any
    renderOption: (option: T) => ReactNode
    onChangeSelect: (clickedOption: T) => void
}

const highlightMatchedLetters = (word: string, searchInput: string): string => {
    return word.replace(new RegExp(searchInput, 'gi'), match => `<b>${match}</b>`)
}

export const MultipleSelectInput = <T extends { name: string }>(
    props: MultipleSelectInputsProps<T>
) => {
    const [searchInput, setSearchInput] = useState('')

    const filteredOptions = props.options.filter(option =>
        option.name.toLowerCase().includes(searchInput.toLowerCase())
    )

    return (
        <InputWrapper
            label={props.labelText}
            error={props.error}
            onClickOutside={() => setSearchInput('')}
            enableResize
        >
            <div className={styles.container}>
                <div className={styles.search}>
                    {props.selected.map(option => (
                        <div
                            className={styles.chip}
                            key={`multiple-select-selection:${option.name}`}
                        >
                            <>{props.renderOption(option)}</>
                            <span
                                className={styles.icon}
                                onClick={() => {
                                    if (!props.disabled){
                                    props.onChangeSelect(option)
                                    }
                                    else{
                                        console.log("clicekd")
                                    }
                                }}
                            >
                                <i className="icon-x" />
                            </span>
                        </div>
                    ))}
                    <input
                        type="text"
                        placeholder={props.placeholder}
                        id="operatorName"
                        value={searchInput}
                        onChange={e => setSearchInput(e.target.value)}
                        className={styles.inputSearch}
                        autoComplete="off"
                    />
                </div>

                {searchInput.length > 0 && (
                    <div className={classnames(styles.options, styles.show)}>
                        {filteredOptions.length > 0 ? (
                            <>
                                {filteredOptions.map(option => (
                                    <div
                                        className={classnames(
                                            styles.option,
                                            props.disabled ? styles.disabled : '',
                                            props.selected.map(op => op.name).includes(option.name)
                                                ? styles.selected
                                                : ''
                                        )}
                                        onClick={() => {
                                            if (!props.disabled) {
                                                props.onChangeSelect(option)
                                            }
                                            else if(props?.disablederror){
                                                Toast({type:'error',message:props?.disablederror})
                                            }
                                            setSearchInput('')
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: highlightMatchedLetters(
                                                option.name,
                                                searchInput
                                            ),
                                        }}
                                        key={`multiple-select-option:${option.name}`}
                                    />
                                ))}
                            </>
                        ) : (
                            <div className={styles.option}>{props.noMatchesText}</div>
                        )}
                    </div>
                )}
            </div>
        </InputWrapper>
    )
}
