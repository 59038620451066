import { useWindowSize } from "../../hooks/useWindowSize"
import { AdminViewTable } from "../admin-view-table/AdminViewTable"
import {FC} from 'react'
import { Mappings } from "../../models/mappings"
import styles from './AdminViewList.module.css'
import { MobileAdminCard } from "../mobileAdminCard/mobileAdminCard"

type AdminViewTableProps = {
    userMappings: Mappings[]
}

export const AdminViewList: FC<AdminViewTableProps> = props => {
    const {isMobileView} = useWindowSize()

    if (isMobileView) {
        return (
            <div className={styles.container}>
                {props.userMappings.map(mapping => (
                    <MobileAdminCard
                        key={mapping.oid}
                        userMapping={mapping}
                    />
                ))}
            </div>
        )
    }

    return <AdminViewTable {...props}/>
}