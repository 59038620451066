import { AdminEntry } from '../models/adminEntry'
import { LogEntry } from '../models/logEntry'

export const SHIFT_TYPE = {
    NIGHT: 'Night',
    DAY: 'Day',
}

export const MOBILE_BREAKPOINT = 768

export type TableHeader =
    | 'Rig Name'
    | 'Operators'
    | 'Shift Date'
    | 'Submitted Date'
    | 'Submitted By'
    | 'Last Modified Date'
    | 'Last Modified By'
    | 'Files Attached'
    | 'Pending Tasks'
    | 'Shift Summary'
    | 'Options'

export type ColumnSelection = { name: TableHeader; checked: boolean }[]

export const DEFAULT_COLUMNS_STATE: ColumnSelection = [
    { name: 'Rig Name', checked: true },
    { name: 'Operators', checked: true },
    { name: 'Shift Date', checked: true },
    { name: 'Submitted Date', checked: true },
    { name: 'Submitted By', checked: true },
    { name: 'Last Modified Date', checked: true },
    { name: 'Last Modified By', checked: true },
    { name: 'Files Attached', checked: false },
    { name: 'Pending Tasks', checked: false },
    { name: 'Shift Summary', checked: false },
]

export const DEFAULT_LOG_ENTRY: LogEntry = {
    internalId: 0,
    operators: [],
    shifttype: '',
    shiftsummary: '',
    pendingtask: '',
    submittedtime: '',
    lastmodifiedtime: '',
    filenames: [],
    id: '',
    shiftdate: '',
}

export const DEFAULT_ADMIN_ENTRY: AdminEntry = {
    rigs: [],
    lastupdatedtime: '',
    lastupdatedby:'',
    oid:'',
    name:'',
    roleids:[],
    rigids:[],
    userrole: []
}

export const SORT_BY_OPTIONS: TableHeader[] = [
    'Rig Name',
    'Submitted Date',
    'Last Modified Date',
    'Shift Date',
    'Files Attached',
    'Submitted By',
]

export const DEFAULT_SORT: TableHeader = 'Submitted Date'

export const REACT_APP_COMPANY_NAME = 'Syzygy Plasmonics'
export const REACT_APP_API_URL =
'https://operatorlogapi.azurewebsites.net/api/lognotes'
// 'http://localhost:7055/api/lognotes'

export const REACT_APP_APPLICATION_CLIENT_ID = 
// '9ea11ac6-6509-4264-8bff-12e600f3b084'
'b8cd0e46-7c4d-45ee-9f2c-c2347da71fc3'
                                                
export const REACT_APP_APPLICATION_AUTHORITY =
    // 'https://login.microsoftonline.com/9dee0590-9ade-48bf-8659-402111e6efea'
    'https://login.microsoftonline.com/3b92c69d-ffab-4f60-9cea-e368ca2607ee'
export const REACT_APP_AZURE_CONTAINER_NAME = 'attachedfilestorage'
