import { useGet } from "../hooks/useGet";
import API_PATHS from "../paths";
import { Role } from "../models/role";

export const useGetAllRoles = (handlers?: {
    onSuccess?: (roles: Role[]) => void
    onError?: () => void
    disabled?: boolean
}) => {
    return useGet<Role[]>({
        ...handlers,
        relativePath: API_PATHS.AllRoles,
        toastErrorText: "Error retrieving roles",
    })
}