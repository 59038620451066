import { FC } from 'react'
import styles from './pendingTasksAccordion.module.css'
import classnames from 'classnames'
import { Accordion } from '../common/accordion/accordion'
import { htmlHasContent } from '../../utils/stringFormatter'
import classNames from 'classnames'

type PendingTasksAccordionProps = {
    pendingTask: string | null
}

export const PendingTasksAccordion: FC<PendingTasksAccordionProps> = props => {
    const pendingTasksHasContent = props.pendingTask && htmlHasContent(props.pendingTask)
    const pendingTasks = pendingTasksHasContent ? props.pendingTask! : '<p>No pending tasks</p>'

    return (
        <div className={styles.container}>
            <Accordion
                summary={isExpanded => (
                    <div className={classnames(styles.summary, isExpanded ? styles.expanded : '')}>
                        <div className={styles.title}>
                            <i className="icon-list" />
                            <h3>Pending Tasks</h3>
                        </div>
                        <button>{isExpanded ? <span>-</span> : <span>+</span>}</button>
                    </div>
                )}
                content={
                    <div
                        className={classNames(styles.text, 'public-DraftEditor-content')}
                        dangerouslySetInnerHTML={{ __html: pendingTasks }}
                    />
                }
            />
        </div>
    )
}
