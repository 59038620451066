import { FC, Fragment } from 'react'
import styles from './pagination.module.css'

type PaginationProps = {
    pagesAmount: number
    currentPage: number
    onClickPage: (pageNumber: number) => void
}

export const Pagination: FC<PaginationProps> = props => {
    const paginationNumbers: number[] = []
    if (props.pagesAmount <= 5) {
        paginationNumbers.push(...Array.from({ length: props.pagesAmount }, (_, i) => i + 1))
    } 
    else {
        paginationNumbers.push(props.currentPage)
        if (props.currentPage > 1) {
            paginationNumbers.unshift(props.currentPage - 1)
        }
        if (props.currentPage < props.pagesAmount) {
            paginationNumbers.push(props.currentPage + 1)
        }
        if (props.currentPage>2){
            paginationNumbers.unshift(1)  
        }
        if (props.currentPage<props.pagesAmount-1){
            paginationNumbers.push(props.pagesAmount)
        }
        
        if (props.currentPage > 3) {
            paginationNumbers.splice(1, 0, -1)
        }
        if (props.currentPage < props.pagesAmount - 2) {
            paginationNumbers.splice(paginationNumbers.length - 1, 0, -1)
        }
    }

    return (
        <div className={styles.pagesSelector}>
            {paginationNumbers.map((pageNum, index) => (
                <Fragment key={`pagination-${pageNum}-${index}`}>
                    {pageNum === -1 ? (
                        <div className={styles.threeDots}>
                            {Array.from({ length: 3 }).map((_, index) => (
                                <span className={styles.dot} key={`pagination-dot:${index}`} />
                            ))}
                        </div>
                    ) : (
                        <span
                            key={index}
                            className={pageNum === props.currentPage ? styles.active : ''}
                            onClick={() => props.onClickPage(pageNum)}
                        >
                            {pageNum}
                        </span>
                    )}
                </Fragment>
            ))}
        </div>
    )
}
