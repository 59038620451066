import { FC } from 'react'
import Button from '../common/button/button'
import styles from './notFoundCard.module.css'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

export const NotFoundCard: FC = () => {
    const navigate = useNavigate()

    return (
        <div className={styles.container}>
            <i className={classNames('icon-not-found', styles.icon)} />

            <h2 className={styles.title}>Page Not Found</h2>

            <p>
                Sorry, the page you are looking for could not be located. Please check the URL or
                navigate back to continue.
            </p>

            <Button
                onClick={() => navigate('/log-entries')}
                variant="blue-bg"
                text="Go Back"
                endIconClassname="icon-arrow-right"
                size="small"
            />
        </div>
    )
}

export default NotFoundCard
