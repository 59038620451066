import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import AdminUserForm from '../components/admin-user-form/AdminUserForm'
import { Layout } from '../components/common/layout/layout'
import useRigs from '../hooks/contexts/useRigs'
import { Role } from '../models/role'
import { useAdminEntryCreate } from '../services/adminEntry'
import { useGetAllRoles } from '../services/roles'

export const CreateUserAdminPage = () => {
    const navigate = useNavigate()
    const { rigs, isLoading: isLoadingRigs, enableFetchRigs } = useRigs()
    const userRoles = useGetAllRoles().data
    userRoles.map((r: Role) => (r.active = false))

    const { mutation } = useAdminEntryCreate({
        onSuccess: () => {
            navigate('/admin')
        },
        onError: () => navigate('/admin'),
    })

    useEffect(() => {
        enableFetchRigs()
        // // eslint-disable-next-line react-hooks/exhaustive-deps\
    }, [enableFetchRigs])

    const initialLoading = isLoadingRigs

    return (
        <Layout pageTitle="Create New User" isLoading={initialLoading} navigationBackUrl="/admin">
            <AdminUserForm
                oid={''}
                name={''}
                isoperator={false}
                active={true}
                lastupdatedtime={''}
                lastupdatedby={''}
                roles={userRoles || []}
                rigs={rigs || []}
                createUser={data => mutation(data)}
            />
        </Layout>
    )
}

export default CreateUserAdminPage
