import { FC, MutableRefObject ,useRef} from "react"
import { useNavigate } from "react-router-dom"
import useOutsideClick from "../../../hooks/useOutsideClick"
import styles from './adminViewModal.module.css'
import { useDispatch} from 'react-redux'
import {SET} from '../../../redux/actionTypes'

type AdminViewModalProps ={
    uoid: string
    onClose?: () => void
}

export const AdminViewModal: FC<AdminViewModalProps> = props => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const ref = useRef()  as MutableRefObject<HTMLUListElement>
    useOutsideClick([ref], () => {
        props.onClose?.()
    })

    const navigateAndClose = (url:string) => {
        dispatch(SET(props.uoid))
        navigate(url)
        props.onClose?.()
    }

    return (
        <ul className={styles.containerOptions} ref={ref}>
            <li onClick={() => navigateAndClose('/admin/create')}>Edit</li>
        </ul>
    )
}