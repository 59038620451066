import { useGet } from '../hooks/useGet'
import { User } from '../models/user'
import API_PATHS from '../paths'

export const useGetAllUsers = (handlers?: {
    onSuccess?: (operators: User[]) => void
    onError?: () => void
    disabled?: boolean
}) => {
    return useGet<User[]>({
        ...handlers,
        relativePath: API_PATHS.AllUsers,
        toastErrorText: 'Error retrieving users',
    })
}

export const useGetUser = (handlers?: {
    userOid?: string
    enabled?: boolean
    onSuccess?: (user: User) => void
    onError?: () => void
    disabled?: boolean
}) => {
    return useGet<User>({
        onError: handlers?.onError,
        onSuccess: handlers?.onSuccess,
        disabled: handlers?.disabled,
        relativePath: `${API_PATHS.Users}/${handlers?.userOid}`,
        toastErrorText: 'Error retrieving user data',
    })
}
