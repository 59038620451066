import { FC, useEffect, useState } from 'react'
import styles from './filesAccordion.module.css'
import classnames from 'classnames'
import FilesTable from '../files-table/filesTable'
import { Accordion } from '../common/accordion/accordion'
import { FileUpload } from '../../models/files'
import { downloadFileFromAzure } from '../../services/fileUploading'
import React from 'react'
import Button from '../common/button/button'
import { Toast } from '../common/toast/toast'
import { useWindowSize } from '../../hooks/useWindowSize'
import { useMsal } from '@azure/msal-react'

type FilesAccordionProps = {
    files?: FileUpload[]
}

export const FilesAccordion: FC<FilesAccordionProps> = props => {
    const { isMobileView } = useWindowSize()
    const { instance } = useMsal()
    const [checkedFiles, setCheckedFiles] = useState<string[]>([])
    const [allFilesChecked, setAllFilesChecked] = useState(false)

    useEffect(() => {
        if (allFilesChecked) setCheckedFiles(props.files?.map(file => file.original) || [])
        else setCheckedFiles([])
    }, [allFilesChecked, props.files])

    const handleDownloadCheckedFiles = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.stopPropagation()
        Toast({
            type: 'info',
            message: 'Downloading your files',
        })
        const { accessToken } = await instance.acquireTokenSilent({
			scopes: ["user.read"],
		});
        const filesToDownload = checkedFiles.reduce(
            (accumulator: { unique: string; fileName: string }[], fileName: string) => {
                const uniqueId = props.files!.find(f => f.original === fileName)?.unique

                if (!uniqueId) return accumulator

                return [
                    ...accumulator,
                    {
                        fileName,
                        unique: uniqueId,
                    },
                ]
            },
            []
        )

        await downloadFileFromAzure(filesToDownload, accessToken)
    }

    return (
        <div className={styles.container}>
            <Accordion
                disabled={!props.files || props.files.length === 0}
                summary={isExpanded => (
                    <div
                        className={classnames(
                            props.files?.length === 0 ? styles.disabled : '',
                            styles.summary,
                            isExpanded ? styles.expanded : ''
                        )}
                    >
                        <div className={styles.title}>
                            <i className="icon-paperclip" />
                            <h3>Files Attached</h3>
                            {!!props.files?.length && <span>{props.files?.length}</span>}
                        </div>
                        <div className={styles.rightTop}>
                            {isExpanded && (
                                <Button
                                    text={isMobileView ? '' : 'Download Selected'}
                                    startIconClassname="icon-download"
                                    variant="transparent-bg"
                                    size="small"
                                    onClick={e => handleDownloadCheckedFiles(e)}
                                    disabled={checkedFiles.length === 0}
                                />
                            )}
                            <button className={styles.roundedButton}>
                                {isExpanded ? <span>-</span> : <span>+</span>}
                            </button>
                        </div>
                    </div>
                )}
                content={
                    <FilesTable
                        files={props.files || []}
                        checkedFiles={checkedFiles}
                        onCheckAllFiles={() => setAllFilesChecked(prev => !prev)}
                        allFilesChecked={allFilesChecked}
                        onCheckFile={fileName =>
                            setCheckedFiles(prev =>
                                prev.includes(fileName)
                                    ? prev.filter(f => f !== fileName)
                                    : [...prev, fileName]
                            )
                        }
                    />
                }
            />
        </div>
    )
}
