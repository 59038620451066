import API_PATHS from "../paths"
import {useGet} from '../hooks/useGet'

type Mapping = {
    user_name: string
    oid: string
    role_name: string
    rig_name: string
}
export const useGetAllMappings = (handlers?: {
    onSuccess?: (mappings: Mapping[]) => void
        onError?: () => void
        disabsed?: boolean
}) => {
    return useGet<Mapping[]>({
        ...handlers,
        relativePath: API_PATHS.AllUserMappings,
        toastErrorText: "Error retrieving mappings"
    })
}