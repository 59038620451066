export const removeFields = (obj: any, keysToAvoid: string[]): any => {
    const newObj: any = {}

    for (const key in obj) {
        if (!keysToAvoid.includes(key)) {
            newObj[key] = obj[key]
        }
    }

    return newObj
}
