import { FC, ReactNode, useState } from 'react'
import styles from './accordion.module.css'
import classnames from 'classnames'

type AccordionProps = {
    disabled?: boolean
    defaultExpanded?: boolean
    summary: (expanded: boolean) => ReactNode
    content: ReactNode
}

export const Accordion: FC<AccordionProps> = props => {
    const [isExpanded, setIsExpanded] = useState(!!props.defaultExpanded)

    return (
        <div className={styles.container}>
            <div
                className={classnames(styles.summary, props.disabled ? styles.disabled : '')}
                onClick={() => {
                    if (!props.disabled) setIsExpanded(prev => !prev)
                }}
            >
                {props.summary(isExpanded)}
            </div>
            <div className={classnames(styles.content, isExpanded ? styles.expanded : '')}>
                {props.content}
            </div>
        </div>
    )
}
