import { ChangeEvent, FC, useState } from 'react'
import Button from '../../common/button/button'
import styles from './selectFilesModal.module.css'
import { ContextModal } from '../../common/context-modal/contextModal'
import useModal from '../../../hooks/contexts/useModal'
import { InputWrapper } from '../../common/input-wrapper/inputWrapper'
import { formatFileSize } from '../../../utils/stringFormatter'
import { FileUpload } from '../../../models/files'

export const UPLOAD_FILES_MODAL_KEY = `upload-files-modal`

type UploadFilesModalProps = {
    onUpload: (filesUploaded: FileUpload[]) => void
}

export const SelectFilesModal: FC<UploadFilesModalProps> = props => {
    const { toggleModal } = useModal()

    const [error, setError] = useState(false)
    const [files, setFiles] = useState<FileUpload[]>([])

    const handleOpenFileExplorer = () => {
        let input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*'
        input.multiple = true
        input.onchange = _ => {
            if (input.files) {
                const filesInsideSizeRange = Array.from(input.files).filter(file => {
                    const sizeInMB = file.size / 1024 ** 2
                    if (sizeInMB > 3) {
                        setError(true)
                        return false
                    }
                    return true
                })

                const files = filesInsideSizeRange.map(file => ({ metadata: file }))

                setFiles(prev =>
                    prev.concat(
                        files.map(file => ({
                            original: file.metadata.name,
                            size: file.metadata.size,
                            metadata: file.metadata,
                        }))
                    )
                )
            }
        }
        input.click()
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>, fileName: string) => {
        setFiles(prev =>
            prev.map(f => {
                if (f.original === fileName) return { ...f, description: event.target.value }
                else return f
            })
        )
    }

    const closeAndClear = () => {
        toggleModal(UPLOAD_FILES_MODAL_KEY)
        setError(false)
        setFiles([])
    }

    return (
        <ContextModal modalKey={UPLOAD_FILES_MODAL_KEY} onClickOutside={closeAndClear} grayOverlay>
            <div className={styles.modalContent}>
                <div className={styles.header}>
                    <div className={styles.titleWrapper}>
                        <h1>Select Files</h1>
                        <i className="icon-x" onClick={closeAndClear} />
                    </div>
                    <p className={styles.addDocument}>Add your documents here.</p>
                </div>

                <div className={styles.upload} onClick={handleOpenFileExplorer}>
                    <i className="icon-upload-cloud" />
                    <h4>Select a file to upload</h4>
                    <p>File size cannot exceed 3MB</p>
                </div>

                {error && (
                    <p className={styles.error}>Files that exceeded the limit were not uploaded.</p>
                )}

                {!!files.length && (
                    <div className={styles.filesContainer}>
                        {files.map((file, index) => (
                            <div
                                className={styles.loader}
                                key={`uploaded-files-${file.original}-${index}`}
                            >
                                <div
                                    className={`${styles.wrapperNameFile} ${styles.withIconTrash}`}
                                >
                                    <div className={styles.nameFile}>
                                        <i className="icon-paperclip" />
                                        <h6>{file.original}</h6>
                                    </div>
                                    <i
                                        className="icon-trash"
                                        onClick={() =>
                                            setFiles(prev =>
                                                prev.filter(f => f.original !== file.original)
                                            )
                                        }
                                    />
                                </div>
                                <span>{formatFileSize(file.size)}</span>
                                <InputWrapper label="File Description" customHeight={100}>
                                    <textarea
                                        value={file.description}
                                        rows={4}
                                        onChange={e => handleDescriptionChange(e, file.original)}
                                        placeholder="Add details"
                                    />
                                </InputWrapper>
                            </div>
                        ))}
                    </div>
                )}
                <footer>
                    <Button
                        variant={'blue-bg'}
                        text="Confirm"
                        onClick={() => {
                            props.onUpload(files)
                            closeAndClear()
                        }}
                    />
                    <Button variant={'gray-bg'} text="Cancel" onClick={closeAndClear} />
                </footer>
            </div>
        </ContextModal>
    )
}
