import { FC, useState } from 'react'
import useAuth from '../../hooks/contexts/useAuth'
import { SubmitHandler, useForm } from 'react-hook-form'
import { DEFAULT_ADMIN_ENTRY } from '../../utils/constants'
import { AdminEntry } from '../../models/adminEntry'
import { Role } from '../../models/role'
import { Rig } from '../../models/rig'
import { CreateUser } from '../../models/createUser'
import { Select } from '../common/select/select'
import { MultipleSelectInput } from '../common/multiple-select-input/multipleSelectInput'
import Button from '../common/button/button'
import styles from './adminUserForm.module.css'
import { Input } from '../common/input/input'

type AdminUserFormProps = {
    entry?: AdminEntry
    oid: string
    name: string
    isoperator: boolean
    active: boolean
    lastupdatedtime: string
    lastupdatedby: string
    createUser: SubmitHandler<CreateUser>
    roles: Role[]
    rigs: Rig[]
}

export const AdminUserForm: FC<AdminUserFormProps> = props => {
    const { user: currentUser } = useAuth()
    const [powerRole, setIsPowerRole] = useState(false)
    const {
        register,
        watch,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<AdminEntry>({ defaultValues: props.entry || DEFAULT_ADMIN_ENTRY })
    const [name, oid, rigs, userrole] = watch(['name', 'oid', 'rigs', 'userrole'])

    const createUser = (data: AdminEntry) => {
        const lastupdatedby = currentUser?.oid
        const updatedRigs = data.rigs.filter((r: Rig) => (!r?.recent && !r.active) || r.active)
        updatedRigs.map((r: Rig) => {
            delete r.recent
        })
        // setValue('rigs', updatedRigs)
        const updatedRoles = data.userrole.filter(
            (r: Role) => (!r?.recent && !r.active) || r.active
        )
        updatedRoles.map((r: Role) => {
            delete r.recent
        })
        const role = updatedRoles[0].role === 'operator' ? true : false
        // setValue('userrole', updatedRoles)
        const createUser = {
            isoperator: role,
            oid: oid,
            name: name,
            active: true,
            lastupdatedtime: new Date().toISOString(),
            lastupdatedby: lastupdatedby,
            roleids: updatedRoles,
            rigids: updatedRigs,
        }
        props.createUser(createUser)
    }

    const rolesById: Record<string, Role> = {}
    props.roles.forEach(role => (rolesById[role.oid] = role))

    const rigsById: Record<string, Rig> = {}
    props.rigs.forEach(rig => (rigsById[rig.oid] = rig))

    return (
        <div className={styles.container}>
            <h1>Create User</h1>
            <div className={styles.inputs}>
                <form className={styles.form}>
                    <Input
                        {...register('name', { required: 'Assign user' })}
                        ref={null}
                        label="User"
                        error={errors.name?.message}
                        type="text"
                        change={value => setValue('name', value)}
                    />
                    <Input
                        {...register('oid', { required: 'Assign oid' })}
                        ref={null}
                        label="OID"
                        error={errors.oid?.message}
                        type="text"
                        change={value => setValue('oid', value)}
                    />
                    <Select<Role>
                        {...register('userrole', {
                            required: 'Choose a role',
                        })}
                        ref={null}
                        options={props.roles || []}
                        renderOption={option => <>{option.role}</>}
                        label="Role"
                        value={userrole.filter((r: Role) => r.active)[0]?.role}
                        onChange={option => {
                            // tracks newly added roles
                            let roleExists = false
                            for (var r of userrole) {
                                if (r.oid !== option.oid) {
                                    r.active = false
                                } else {
                                    r.active = true
                                    roleExists = true
                                }
                            }
                            if (!roleExists) {
                                userrole.push({ ...option, active: true, recent: true })
                            }
                            setIsPowerRole(option.role === 'manager' || option.role === 'admin')
                            // below either clears the rig entries for managers or autofills
                            if (option.role === 'manager') {
                                setValue('rigs', [
                                    {
                                        name: 'ALL',
                                        oid: 'be5c7c7a-67a1-4698-bdf1-2851d7e38569',
                                        active: true,
                                    },
                                ])
                            }
                            if (option.role !== 'manager' && option.role !== 'admin' && powerRole) {
                                setValue('rigs', [])
                            }

                            setValue('userrole', userrole)
                        }}
                        error={errors.userrole?.message}
                    />
                    <MultipleSelectInput<Rig>
                        {...register('rigs', {
                            required: 'Assign rigs',
                            minLength: 1,
                        })}
                        options={props.rigs}
                        ref={null}
                        renderOption={option => <>{option.name}</>}
                        labelText="Rigs"
                        disabled={powerRole}
                        disablederror="Can't change rigs on Admins or Managers"
                        selected={rigs.filter((r: Rig) => r.active)}
                        onChangeSelect={(rig: Rig) => {
                            const wasSelected = rigs
                                ?.map((r: Rig) => r.active === true && r?.name)
                                .includes(rig.name)
                            // instead of removing the rig from the array, it changes the "active" tag to false
                            if (wasSelected) {
                                rigs.map((r: Rig) =>
                                    r.oid === rig.oid ? (r.active = false) : null
                                )
                            } else {
                                // tracks newly added rigs
                                let rigExists = false
                                for (var r of rigs) {
                                    if (r.oid === rig.oid) {
                                        rigExists = true
                                        r.active = true
                                        break
                                    }
                                }
                                if (!rigExists) {
                                    rigs.push({ ...rig, recent: true })
                                }
                            }
                            setValue('rigs', rigs)
                        }}
                        noMatchesText="No Rigs Found"
                        error={errors.rigs?.message}
                    />
                </form>
            </div>
            <footer>
                <Button variant="blue-bg" onClick={handleSubmit(createUser)} text="Save" />
            </footer>
        </div>
    )
}

export default AdminUserForm
