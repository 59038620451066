import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import { FileSasToken } from '../models/files'
import API_PATHS from '../paths'
import { Toast } from '../components/common/toast/toast'
import { Guid } from 'js-guid'
import fileDownload from 'js-file-download'
import { REACT_APP_API_URL, REACT_APP_AZURE_CONTAINER_NAME } from '../utils/constants'

const containerName = REACT_APP_AZURE_CONTAINER_NAME || ''

export const uploadFilesToAzure = async (files: File[], jwt?: string) => {
    const filesWithUniqueId: { file: File; unique: string }[] = []

    const requestOptions = {
        headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json',
        },
    }

    const { token }: FileSasToken = await (
        await fetch(`${REACT_APP_API_URL}${API_PATHS.FileToken}/2`, requestOptions)
    ).json()

    const blobServiceClient = new BlobServiceClient(token)
    const containerClient: ContainerClient = blobServiceClient.getContainerClient(containerName)

    for (const file of files) {
        const unique = Guid.newGuid().toString()

        const blobClient = containerClient.getBlockBlobClient(unique)

        const options = { blobHTTPHeaders: { blobContentType: file.type } }

        const response = await blobClient.uploadData(file, options)

        if (response._response.status === 201) {
            Toast({ type: 'success', message: `Saved File: ${file.name}` })
            filesWithUniqueId.push({
                unique,
                file,
            })
        } else {
            Toast({ type: 'error', message: `Unable to save file` })
        }
    }

    return filesWithUniqueId
}

export const deleteFileFromAzure = async (uniqueFileIds: string[], jwt?: string) => {
    const requestOptions = {
        headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json',
        },
    }

    const { token }: FileSasToken = await (
        await fetch(`${REACT_APP_API_URL}${API_PATHS.FileToken}/9`, requestOptions)
    ).json()

    const blobServiceClient = new BlobServiceClient(token)
    const containerClient = blobServiceClient.getContainerClient(containerName)

    uniqueFileIds.forEach(async fileUniqueId => {
        const blobClient = containerClient.getBlockBlobClient(fileUniqueId)

        const response = await blobClient.delete()

        if (response._response.status === 202) {
            Toast({ type: 'success', message: `File deleted successfully` })
        } else {
            Toast({ type: 'error', message: `Unable to delete file` })
        }
    })
}

export const downloadFileFromAzure = async (
    files: { unique: string; fileName: string }[],
    jwt?: string
) => {
    const requestOptions = {
        headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'application/json',
        },
    }

    const { token }: FileSasToken = await (
        await fetch(`${REACT_APP_API_URL}${API_PATHS.FileToken}/4`, requestOptions)
    ).json()

    const blobServiceClient = new BlobServiceClient(token)
    const containerClient = blobServiceClient.getContainerClient(containerName)

    files.forEach(async ({ fileName, unique: fileUniqueId }) => {
        const blobClient = await containerClient.getBlobClient(fileUniqueId)

        if (token) {
            const downloadResponse = await (await blobClient.download()).blobBody

            if (downloadResponse) fileDownload(downloadResponse, fileName)
        } else {
            Toast({ type: 'error', message: `Unable to download file` })
        }
    })
}
