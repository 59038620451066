import styles from './logEntriesList.module.css'
import { LogEntry } from '../../models/logEntry'
import { useWindowSize } from '../../hooks/useWindowSize'
import MobileEntryCard from '../mobile-entry-card/mobileEntryCard'
import { FC } from 'react'
import { TableHeader } from '../../utils/constants'
import { LogEntriesTable } from '../log-entries-table/LogEntriesTable'

type EntriesListProps = {
    logEntries: LogEntry[]
    columnsSelected: {
        name: TableHeader
        checked: boolean
    }[]
}

export const LogEntriesList: FC<EntriesListProps> = props => {
    const { isMobileView } = useWindowSize()

    if (isMobileView) {
        return (
            <div className={styles.container}>
                {props.logEntries.map(entry => (
                    <MobileEntryCard
                        key={entry.id}
                        entry={entry}
                        columnsSelected={props.columnsSelected}
                    />
                ))}
            </div>
        )
    }

    return <LogEntriesTable {...props} />
}

export default LogEntriesList
