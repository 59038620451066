import { LogEntry } from '../models/logEntry'
import { User } from '../models/user'
import { Role } from '../models/role'

export const userHasEditPermission = (logEntry: LogEntry, user?: User) => {
    // const isOperatorMatched = logEntry.operators.some(operator => operator.oid === user?.oid);
    // console.log("User Has Permission", isOperatorMatched)

    switch (user?.roles?.filter((r: Role) => r.active)[0].role) {
        case 'operator':
            return logEntry.submittedby?.oid === user.oid
            // return isOperatorMatched
        case 'supervisor':
            return (
                logEntry.rigname &&
                (user.rigs?.map(rig => rig.oid).includes(logEntry.rigname.oid) ||
                    logEntry.submittedby?.oid === user.oid)
            )
        case undefined:
            return false
        default:
            return true
    }
}

export const getLogEntriesByUserRole = (logEntries: LogEntry[], user?: User) => {
    if (!user) return []

    switch (user?.roles?.filter((r:Role)=>r.active)[0].role) {
        case 'operator':
        case 'supervisor':
            return logEntries.filter(
                entry => entry.rigname && user?.rigs?.map(rig => rig.oid).includes(entry.rigname?.oid)
            )
        case 'admin':
        case 'manager':
            return logEntries
        default:
            return []
    }
}
