import { useNavigate, useParams } from 'react-router-dom'
import useLogEntry from '../hooks/contexts/useLogEntry'
import LogEntryForm from '../components/log-entry-form/logEntryForm'
import { Layout } from '../components/common/layout/layout'
import { useLogEntryEdit } from '../services/logEntry'
import useAuth from '../hooks/contexts/useAuth'
import { useEffect } from 'react'
import { userHasEditPermission } from '../utils/permission'
import { Toast } from '../components/common/toast/toast'
import useRigs from '../hooks/contexts/useRigs'
import useUsers from '../hooks/contexts/useUsers'

const EditLogEntryPage = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { user, isFetchingUserRoles } = useAuth()

    const { logEntriesById, refetch: refetchLogEntries } = useLogEntry()
    const { rigs, isLoading: isLoadingRigs, enableFetchRigs } = useRigs()
    const { users: operators, isLoading: isLoadingOperators, enableFetchUsers } = useUsers()

    const isLogEntriesIdEmpty = Object.keys(logEntriesById).length === 0

    useEffect(() => {
        enableFetchRigs()
        enableFetchUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { mutation } = useLogEntryEdit({
        onSuccess: () => {
            refetchLogEntries()
            navigate('/log-entries')
        },
        onError: () => navigate('/log-entries'),
    })

    useEffect(() => {
        const logEntry = logEntriesById[+id!]
        if (user && logEntry && !userHasEditPermission(logEntry, user)) {
            Toast({ type: 'error', message: 'Access denied' })
            navigate('/log-entries')
        }
    }, [id, logEntriesById, navigate, user])

    const initialLoading = isLoadingOperators || isLoadingRigs || isFetchingUserRoles

    return (
        <Layout
            pageTitle="Edit New Log Entry"
            navigationBackUrl="/log-entries"
            isLoading={isLogEntriesIdEmpty ||initialLoading}
        >
            <LogEntryForm
                entry={logEntriesById[+id!]}
                rigs={rigs || []}
                operators={operators || []}
                onSave={data => mutation(data)}
            />
        </Layout>
    )
}

export default EditLogEntryPage
