import { FC, ReactNode } from 'react'
import useModal, { ModalKey } from '../../../hooks/contexts/useModal'
import styles from './contextModal.module.css'
import classNames from 'classnames'

type ContextModalProps = {
    modalKey: ModalKey
    children: ReactNode
    grayOverlay?: boolean
    onClickOutside?: () => void
}

export const ContextModal: FC<ContextModalProps> = props => {
    const { modalKeys, toggleModal } = useModal()

    if (!modalKeys.includes(props.modalKey)) return null

    return (
        <>
            <div
                className={classNames(styles.overlay, props.grayOverlay ? styles.grayOverlay : '')}
                onClick={e => {
                    e.stopPropagation()
                    toggleModal(props.modalKey)
                    props.onClickOutside?.()
                }}
            />
            {props.children}
        </>
    )
}
