import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { useGetAllUsers } from '../../services/user'
import { User } from '../../models/user'
import useAuth from './useAuth'

export interface UsersContextValue {
    users?: User[]
    usersById: Record<number, User>
    isLoading: boolean
    enableFetchUsers: () => void
}

export const UsersContext = createContext<UsersContextValue>({
    users: [],
    usersById: {},
    isLoading: false,
    enableFetchUsers: () => {},
})

export const UsersProvider: FC<{
    children?: ReactNode
}> = props => {
    const { user } = useAuth()
    const [users, setUsers] = useState<User[]>()
    const [enabled, setEnabled] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [usersById, setUsersById] = useState<Record<string, User>>({})

    const { isLoading: isFetching } = useGetAllUsers({
        disabled: !enabled || !user,
        onSuccess: setUsers,
        onError: () => {
            setIsLoading(false)
            setEnabled(false)
        },
    })

    useEffect(() => {
        if (users) {
            setIsLoading(true)
            const usersMap: Record<string, User> = {}
            users.forEach(user => {
                if (user.oid) {
                    usersMap[user.oid] = user
                }
            })
            setUsersById(usersMap)
            setIsLoading(false)
        }
    }, [users])

    const enableFetch = () => {
        if (!enabled) {
            setIsLoading(true)
            setEnabled(true)
        }
    }

    return (
        <UsersContext.Provider
            value={{
                users: users,
                usersById: usersById,
                isLoading: isFetching || isLoading,
                enableFetchUsers: enableFetch,
            }}
        >
            {props.children}
        </UsersContext.Provider>
    )
}

export const useUsers = (): UsersContextValue => useContext(UsersContext)

export default useUsers
