import { useState } from 'react'
import {
    deleteFileFromAzure,
    downloadFileFromAzure,
    uploadFilesToAzure,
} from '../services/fileUploading'
import { useMsal } from '@azure/msal-react'

export function useFiles() {
    const { instance } = useMsal()
    const [loadingState, setLoadingState] = useState({
        uploading: false,
        downloading: false,
        deleting: false,
    })

    const getAccessToken = async () => {
        const { accessToken } = await instance.acquireTokenSilent({ scopes: ['user.read'] })
        return accessToken
    }

    const uploadFiles = async (
        files: File[]
    ): Promise<
        {
            file: File
            unique: string
        }[]
    > => {
        setLoadingState(prev => ({ ...prev, uploading: true }))
        const accessToken = await getAccessToken()
        const response = await uploadFilesToAzure(files, accessToken)
        setLoadingState(prev => ({ ...prev, uploading: false }))
        return response
    }

    const deleteFiles = async (uniqueFileIds: string[]) => {
        setLoadingState(prev => ({ ...prev, deleting: true }))
        const accessToken = await getAccessToken()
        const response = await deleteFileFromAzure(uniqueFileIds, accessToken)
        setLoadingState(prev => ({ ...prev, deleting: false }))
        return response
    }

    const downloadFiles = async (files: { unique: string; fileName: string }[]) => {
        setLoadingState(prev => ({ ...prev, downloading: true }))
        const accessToken = await getAccessToken()
        await downloadFileFromAzure(files, accessToken)
        setLoadingState(prev => ({ ...prev, downloading: false }))
    }

    return { loadingState, uploadFiles, deleteFiles, downloadFiles }
}
