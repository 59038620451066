import { useNavigate } from 'react-router-dom'
import styles from './entryOptionsModal.module.css'
import { FC, MutableRefObject, useRef } from 'react'
import useOutsideClick from '../../../hooks/useOutsideClick'

export const ENTRY_OPTIONS_MODAL = (entryId: number) => `entr-options-modal-${entryId}`

type EntryOptionsModalProps = {
    entryId: number
    disableEdition?: boolean
    onClose?: () => void
}

export const EntryOptionsModal: FC<EntryOptionsModalProps> = props => {
    const navigate = useNavigate()

    const ref = useRef() as MutableRefObject<HTMLUListElement>
    useOutsideClick([ref], () => {
        props.onClose?.()
    })

    const navigateAndClose = (url: string) => {
        navigate(url)
        props.onClose?.()
    }

    return (
        <ul className={styles.containerOptions} ref={ref}>
            <li onClick={() => navigateAndClose(`/entry-detail/${props.entryId}`)}>View Detail</li>
            <li
                onClick={() => {
                    if (!props.disableEdition) navigateAndClose(`/edit-entry/${props.entryId}`)
                }}
                className={props.disableEdition ? styles.disabled : ''}
            >
                Edit
            </li>
        </ul>
    )
}
