import { MutableRefObject, ReactNode, useRef, useState } from 'react'
import styles from './select.module.css'
import { InputWrapper } from '../input-wrapper/inputWrapper'
import classnames from 'classnames'
import useOutsideClick from '../../../hooks/useOutsideClick'

type SelectProps<T> = {
    label?: string
    options: T[]
    error?: string
    value?: string
    ref?: any
    withoutWrapper?: boolean
    renderOption: (option: T) => ReactNode
    onChange: (option: T) => void
}

export const Select = <T extends {}>(props: SelectProps<T>) => {
    const [optionsOpened, setOptionsOpened] = useState(false)

    const ref = useRef() as MutableRefObject<HTMLUListElement>
    useOutsideClick([ref], () => {
        if (optionsOpened && props.withoutWrapper) {
            setOptionsOpened(false)
        }
    })

    if (props.withoutWrapper)
        return (
            <>
                <div
                    className={styles.select}
                    onClick={() => {
                        if (!optionsOpened) setOptionsOpened(true)
                    }}
                >
                    <span>{props.value}</span>
                    <i className={optionsOpened ? 'icon-chevron-up' : 'icon-chevron-down'} />

                    <ul
                        className={classnames(styles.options, optionsOpened ? styles.show : '')}
                        ref={ref}
                    >
                        {props.options.map((option, index) => (
                            <li
                                onClick={e => {
                                    e.stopPropagation()
                                    props.onChange(option)
                                    setOptionsOpened(false)
                                }}
                                key={`select-option-${option}-${index}`}
                            >
                                {props.renderOption(option)}
                            </li>
                        ))}
                    </ul>
                </div>
            </>
        )

    return (
        <InputWrapper
            onClick={() => {
                setOptionsOpened(prev => !prev)
            }}
            onClickOutside={() => {
                if (optionsOpened) {
                    setOptionsOpened(prev => !prev)
                }
            }}
            error={props.error}
            endIcon={optionsOpened ? 'chevron-up' : 'chevron-down'}
            label={props.label}
        >
            <span>{props.value}</span>
            <ul className={classnames(styles.options, optionsOpened ? styles.show : '')}>
                {props.options.map((option, index) => (
                    <li
                        onClick={e => {
                            e.stopPropagation()
                            props.onChange(option)
                            setOptionsOpened(false)
                        }}
                        key={`select-option-${option}-${index}`}
                    >
                        {props.renderOption(option)}
                    </li>
                ))}
            </ul>
        </InputWrapper>
    )
}
