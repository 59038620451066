import { FC, ReactNode, createContext, useContext, useState } from 'react'
import { User } from '../../models/user'
import { useGetUser } from '../../services/user'
import { AccountInfo } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { Role } from '../../models/role'

export interface AuthContextValue {
    user?: User
    isOperator: boolean
    isSupervisor: boolean
    isManager: boolean
    isAdmin: boolean
    isFetchingUserRoles: boolean
    setAuthenticatedAccount: (account: AccountInfo) => void
    signOut: () => void
}

export const AuthContext = createContext<AuthContextValue>({
    isOperator: false,
    isManager: false,
    isSupervisor: false,
    isAdmin: false,
    isFetchingUserRoles: false,
    setAuthenticatedAccount: () => {},
    signOut: () => {},
})

export const AuthProvider: FC<{
    children?: ReactNode
}> = props => {
    const navigate = useNavigate()
    const { instance } = useMsal()
    const [user, setUser] = useState<User>()
    const [authenticatedAccount, setAuthenticatedAccount] = useState<AccountInfo>()

    const { isLoading } = useGetUser({
        disabled: !authenticatedAccount,
        userOid: authenticatedAccount?.localAccountId,
        onSuccess: userRoles => {
            setUser(userRoles)
        },
        onError: () => {
            setUser(undefined)
            instance.setActiveAccount(null)
            navigate('/')
        },
    })

    const setUserAuthenticatedAccount = (account: AccountInfo) => {
        if (!authenticatedAccount) setAuthenticatedAccount(account)
    }

    const signOut = () => {
        setUser(undefined)
        setAuthenticatedAccount(undefined)
        instance.setActiveAccount(null)
    }

    const userRole = user?.roles?.filter((r:Role)=>r.active)[0]
    const isOperator = !!user && userRole?.role === 'operator'
    const isSupervisor = !!user && userRole?.role === 'supervisor'
    const isManager = !!user && userRole?.role === 'manager'
    const isAdmin = !!user && userRole?.role === 'admin'

    return (
        <AuthContext.Provider
            value={{
                user,
                isManager,
                isOperator,
                isSupervisor,
                isAdmin,
                isFetchingUserRoles: isLoading,
                setAuthenticatedAccount: setUserAuthenticatedAccount,
                signOut,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export const AuthConsumer = AuthContext.Consumer

const useAuth = (): AuthContextValue => useContext(AuthContext)

export default useAuth
