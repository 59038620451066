import { FC, useEffect, useState } from 'react'
import { LogEntry } from '../../models/logEntry'
import { MultipleSelectInput } from '../common/multiple-select-input/multipleSelectInput'
import styles from './logEntryForm.module.css'
import {
    UPLOAD_FILES_MODAL_KEY,
    SelectFilesModal,
} from '../modals/upload-files-modal/selectFilesModal'
import useModal from '../../hooks/contexts/useModal'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import FilesTable from '../files-table/filesTable'
import { DEFAULT_LOG_ENTRY } from '../../utils/constants'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '../common/button/button'
import {
    TIME_SELECTOR_KEY,
    TimeSelectorModal,
} from '../modals/time-selector-modal/timeSelectorModal'
import { InputWrapper } from '../common/input-wrapper/inputWrapper'
import { Select } from '../common/select/select'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { HtmlEditor } from '../common/hmtl-editor/htmlEditor'
import { Rig } from '../../models/rig'
import { User } from '../../models/user'
import draftToHtml from 'draftjs-to-html'
import classNames from 'classnames'
import { Accordion } from '../common/accordion/accordion'
import { DELETE_FILE_MODAL_KEY, DeleteFileModal } from '../modals/delete-file-modal/deleteFileModal'
import { FileUpload } from '../../models/files'
import { inRange } from 'lodash'
import { useFiles } from '../../hooks/useFiles'
import useAuth from '../../hooks/contexts/useAuth'
import { htmlHasContent } from '../../utils/stringFormatter'
import { Role } from '../../models/role'
import API_PATHS from "../../paths"
import { REACT_APP_API_URL } from "../../utils/constants"

type EntryFormProps = {
    entry?: LogEntry
    rigs: Rig[]
    operators: User[]
    onSave: SubmitHandler<LogEntry>
}

const getEditorStateWithContent = (html: string) => {
    const blocksFromHTML = convertFromHTML(html)
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    )
    return () => EditorState.createWithContent(state)
}

export const LogEntryForm: FC<EntryFormProps> = props => {
    const { user } = useAuth()
    const { toggleModal } = useModal()
    const {
        register,
        setValue,
        handleSubmit,
        watch,
        formState: { errors },
        reset,
    } = useForm<LogEntry>({ defaultValues: props.entry || DEFAULT_LOG_ENTRY })

    const { deleteFiles, uploadFiles, loadingState } = useFiles()

    const [operators, shiftTime, rigName] = watch(['operators', 'shifttime', 'rigname'])

    const [filesDeletedIds, setFilesDeletedIds] = useState<string[]>([])
    const [filesAttached, setFilesAttached] = useState<FileUpload[]>(props.entry?.filenames || [])
    const [checkedFiles, setCheckedFiles] = useState<string[]>([])
    const [allFilesChecked, setAllFilesChecked] = useState(false)
    const [date, setDate] = useState(props.entry ? new Date(props.entry.shiftdate) : new Date())

    const [editorErrors, setEditorErrors] = useState<{ summary?: string; pendingTasks?: string }>({
        summary: undefined,
        pendingTasks: undefined,
    })
    const [shiftSummaryEditor, setShiftSummaryEditor] = useState(
        props.entry?.shiftsummary
            ? getEditorStateWithContent(props.entry.shiftsummary)
            : EditorState.createEmpty()
    )
    const [pendingTasksEditor, setPendingTasksEditor] = useState(
        props.entry?.pendingtask
            ? getEditorStateWithContent(props.entry.pendingtask)
            : EditorState.createEmpty()
    )

    const isLoading = loadingState.deleting || loadingState.uploading

    useEffect(() => {
        if (allFilesChecked) setCheckedFiles(filesAttached.map(file => file.original))
        else setCheckedFiles([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (props?.entry?.rigname?.oid){
            filteredOperators(props.entry.rigname.oid)
        }
    }, [allFilesChecked])

    const handleResetFields = () => {
        reset(props.entry || DEFAULT_LOG_ENTRY)
        setFilesDeletedIds([])
        setFilesAttached(props.entry?.filenames || [])
        setPendingTasksEditor(getEditorStateWithContent(props.entry?.pendingtask || ''))
        setShiftSummaryEditor(getEditorStateWithContent(props.entry?.shiftsummary || ''))
    }

    // If selectedFilesName is undefined, the method delete all files
    const handleDeleteFile = (deletedFilesName?: string[]) => {
        const shouldDeleteAllFiles = deletedFilesName === undefined

        if (shouldDeleteAllFiles) {
            // The files without unique, were not uploaded yet, so we don't need to remove them
            const filesToDelete = filesAttached.filter(file => file.unique)

            setFilesDeletedIds(filesToDelete.map(file => file.unique!))

            setFilesAttached([])
        } else {
            const filesToDelete = filesAttached.filter(
                file => file.unique && deletedFilesName.includes(file.original)
            )

            setFilesDeletedIds(filesToDelete.map(file => file.unique!))

            setFilesAttached(prev =>
                prev.filter(file => !deletedFilesName?.includes(file.original))
            )
        }
    }

    const handleCheckFile = (fileName: string) => {
        if (checkedFiles.includes(fileName))
            setCheckedFiles(prev => prev.filter(file => file !== fileName))
        else setCheckedFiles(prev => [...prev, fileName])
    }

    // Remove the deleted files and upload the files that were not uploaded before
    const updateFilesOnAzure = async (): Promise<FileUpload[]> => {
        const attachedFilesMap: Record<string, FileUpload> = {}
        filesAttached.forEach(file => {
            attachedFilesMap[file.original] = file
        })

        if (filesDeletedIds.length) await deleteFiles(filesDeletedIds)

        // Upload the files selected that were not uploaded before
        const filesNeverUploaded = filesAttached
            .filter(file => !file.unique && file.metadata)
            .map(file => file.metadata!)

        let newFilesUploaded: FileUpload[] = []

        if (filesNeverUploaded.length) {
            const filesUploadedWithIds = await uploadFiles(filesNeverUploaded)

            // Now we should add the unique ids to the files that were uploaded
            newFilesUploaded = filesUploadedWithIds.map(({ file, unique }) => ({
                original: file.name,
                size: file.size,
                unique,
                metadata: file,
                description: attachedFilesMap[file.name].description,
            }))
        }

        const filesUploadedBefore = filesAttached.filter(file => file.unique)

        return filesUploadedBefore.concat(newFilesUploaded)
    }

    const onSave = async (data: LogEntry) => {
        // Getting html string from editors
        const pendingTasks = draftToHtml(convertToRaw(pendingTasksEditor.getCurrentContent()))
        const shiftSummary = draftToHtml(convertToRaw(shiftSummaryEditor.getCurrentContent()))

        if (!htmlHasContent(pendingTasks) || !htmlHasContent(shiftSummary)) {
            setEditorErrors({
                summary: !htmlHasContent(shiftSummary) ? 'This is required' : undefined,
                pendingTasks: !htmlHasContent(pendingTasks) ? 'This is required' : undefined,
            })
            return
        } else {
            setEditorErrors({ summary: undefined, pendingTasks: undefined })
        }

        const filesUpdated = await updateFilesOnAzure()

        const dateWithTime = date
        let shifType: 'Day' | 'Night' = 'Night'

        // Create shift date
        if (data.shifttime) {
            const time: string[] = data.shifttime?.replace(' ', '').split(':')

            const hours = +time[0]
            const minutes = +time[1]

            dateWithTime.setHours(hours)
            dateWithTime.setMinutes(minutes)

            shifType = inRange(hours, 6, 18) ? 'Day' : 'Night'
        }

        const newEntry: LogEntry = {
            ...data,
            pendingtask: pendingTasks,
            shiftsummary: shiftSummary,
            shiftdate: dateWithTime.toISOString(),
            shifttype: shifType,
            submittedtime: data.submittedtime ? new Date(data.submittedtime).toISOString() : new Date().toISOString(),
            lastmodifiedby: {
                name: user!.name,
                oid: user!.oid,
            },
            submittedby: props.entry?.submittedby || {
                name: user!.name,
                oid: user!.oid,
            },
            lastmodifiedtime: new Date().toISOString(),
            filenames: filesUpdated,
        }
        props.onSave(newEntry)
    }

    const rigsId = user?.rigs?.map(rig => rig.oid)
    const currentUserRole = user?.roles?.filter((r:Role)=>r.active)[0].role
    const userRigs = props.rigs.filter(
        rig => currentUserRole === 'manager' || 
        currentUserRole === 'admin' ||
        (rig.active && rigsId?.includes(rig.oid))
    )

    // changes options for operators dropdown based on rig selected for input
    // makes it so that only those assigned to the selected Rig can be 
    // put as operators in the log
    const [operatorOptions, setOperatorOptions] = useState(props.operators)
    const filteredOperators = (rgoid:string) => {
        fetch(`${REACT_APP_API_URL}${API_PATHS.OperatorsFromRig}/${rgoid}`)
        .then(async response => {
            const rigoperators = await response.json()
            if (!props?.entry?.operators || (props?.entry.rigname?.oid!==rgoid)){
                setValue('operators',[])
            }
            setOperatorOptions(rigoperators)

        })
    }

    const rigsById: Record<string, Rig> = {}
    props.rigs.forEach(rig => (rigsById[rig.oid] = rig))
    const operatorsById: Record<string, User> = {}
    props.operators.forEach(operator => (operatorsById[operator.oid] = operator))

    return (
        <>
            <div className={styles.container}>
                <div className={styles.inputs}>
                    <form className={styles.form}>
                        <Select<User>
                            {...register('rigname', {
                                required: 'Choose a rig',
                                minLength: 1,
                            })}
                            ref={null}
                            options={userRigs}
                            value={rigName?.name}
                            onChange={option => {
                                setValue('rigname', rigsById[option.oid])
                                filteredOperators(option.oid)
                            }}
                            label="Rig Name"
                            error={errors.rigname?.message}
                            renderOption={option => <>{option.name}</>}
                        />

                        <MultipleSelectInput<User>
                            {...register('operators', {
                                required: 'Choose at least 1 operator and maximum 5',
                                minLength: 1,
                                maxLength: 5,
                            })}
                            ref={null}
                            // options={props.operators}
                            options = {operatorOptions}
                            selected={operators}
                            // disabled if no rig is selected
                            disabled= {rigName==null}
                            disablederror='Please select a Rig first'
                            onChangeSelect={(operator: User) => {
                                const wasSelected = operators
                                    ?.map((op: User) => op?.name)
                                    .includes(operator.name)
                                setValue(
                                    'operators',
                                    wasSelected
                                        ? operators.filter((op: User) => op.oid !== operator.oid)
                                        : [...operators, operatorsById[operator.oid]]
                                )
                            }}
                            noMatchesText="No operators matched"
                            labelText="Operators*"
                            error={errors.operators?.message}
                            renderOption={option => <>{option.name}</>}
                        />

                        <InputWrapper
                            label="Shift Date"
                            error={errors.shiftdate?.message}
                            endIcon="calendar"
                        >
                            <DatePicker
                                onChange={date => {
                                    if (date) {
                                        setDate(date)
                                    }
                                }}
                                autoComplete="off"
                                value={date.toLocaleDateString()}
                                selected={date}
                                maxDate={new Date()}
                                adjustDateOnChange={false}
                                id="shiftdate"
                            />
                        </InputWrapper>

                        <InputWrapper
                            label="Shift Time"
                            error={errors.shiftdate?.message}
                            endIcon="clock"
                            onClick={() => toggleModal(TIME_SELECTOR_KEY)}
                        >
                            <span>{shiftTime}</span>
                            <TimeSelectorModal
                                defaultTime={[date.getHours(), date.getMinutes()]}
                                onSave={time => {
                                    setValue('shifttime', `${time[0]} : ${time[1]} `)
                                }}
                            />
                        </InputWrapper>

                        <InputWrapper
                            label="Pending Tasks*"
                            customHeight={300}
                            error={editorErrors.pendingTasks}
                        >
                            <HtmlEditor
                                editor={pendingTasksEditor}
                                onEditorStateChange={setPendingTasksEditor}
                            />
                        </InputWrapper>
                    </form>

                    <div className={styles.form}>
                        <InputWrapper
                            label="Shift Summary*"
                            customHeight={524}
                            error={editorErrors.summary}
                        >
                            <HtmlEditor
                                editor={shiftSummaryEditor}
                                onEditorStateChange={setShiftSummaryEditor}
                            />
                        </InputWrapper>

                        <button
                            className={styles.files}
                            onClick={() => toggleModal(UPLOAD_FILES_MODAL_KEY)}
                        >
                            <i className="icon-plus" />
                            <span>Add files</span>
                        </button>
                        <SelectFilesModal
                            onUpload={selectedFiles =>
                                setFilesAttached(prev => [...prev, ...selectedFiles])
                            }
                        />
                    </div>
                </div>

                {!!filesAttached.length && (
                    <Accordion
                        summary={isExpanded => (
                            <div className={styles.accordionSummary}>
                                <div className={styles.leftSide}>
                                    <div className={styles.title}>
                                        <h3>Files Attached</h3>
                                        <i
                                            className={classNames(
                                                `icon-chevron-down`,
                                                isExpanded ? styles.rotated : ''
                                            )}
                                        />
                                    </div>
                                    <span>Files that have been attached to this entry.</span>
                                </div>
                                {isExpanded && (
                                    <>
                                        <Button
                                            text="Remove Selected"
                                            startIconClassname="icon-trash"
                                            variant="transparent-bg"
                                            size="small"
                                            disabled={checkedFiles.length === 0}
                                            onClick={e => {
                                                e.stopPropagation()
                                                toggleModal(DELETE_FILE_MODAL_KEY('selected-files'))
                                            }}
                                        />

                                        <DeleteFileModal
                                            fileName="selected-files"
                                            onDelete={() => handleDeleteFile(checkedFiles)}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                        content={
                            <FilesTable
                                isEditing
                                files={filesAttached}
                                checkedFiles={checkedFiles}
                                allFilesChecked={allFilesChecked}
                                onDeleteFiles={handleDeleteFile}
                                onCheckFile={handleCheckFile}
                                onCheckAllFiles={() => setAllFilesChecked(prev => !prev)}
                            />
                        }
                        defaultExpanded
                    />
                )}
            </div>
            <footer>
                <Button
                    onClick={handleSubmit(onSave)}
                    variant="blue-bg"
                    text={isLoading ? 'Saving' : 'Save'}
                    size={'medium'}
                    isLoading={isLoading}
                />
                <Button onClick={handleResetFields} variant="gray-bg" text="Reset" />
            </footer>
        </>
    )
}

export default LogEntryForm
