import { useGet } from '../hooks/useGet'
import { Rig } from '../models/rig'
import API_PATHS from '../paths'

export const useGetAllRigs = (handlers?: {
    onSuccess?: (operators: Rig[]) => void
    onError?: () => void
    disabled?: boolean
}) => {
    return useGet<Rig[]>({
        ...handlers,
        relativePath: API_PATHS.AllRigs,
        toastErrorText: 'Error retrieving rigs',
    })
}
