import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import Button from '../common/button/button'
import styles from './headerButton.module.css'

type HeaderButtonProps = {
    onClick: () => void
}

export const HeaderButton: FC<HeaderButtonProps> = (props: HeaderButtonProps) => {
    const location = useLocation()
    return (
        <div className={styles.topRight}>
            <Button
                onClick={props.onClick}
                variant="blue-bg"
                text={location.pathname === '/admin' ? 'Create User Entry' : 'Create New Entry'}
                startIconClassname="icon-plus"
            />
        </div>
    )
}
