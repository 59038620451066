import {
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    ColumnDef,
    flexRender,
    getSortedRowModel,
    SortingState,
} from '@tanstack/react-table'
import styles from './table.module.css'
import { useState } from 'react'
import { Select } from '../select/select'
import { Pagination } from '../pagination/pagination'

type TableProps<T extends {}> = {
    columns: ColumnDef<T>[]
    data: T[]
}

export const Table = <T extends {}>(props: TableProps<T>) => {
    const [sorting, setSorting] = useState<SortingState>([])

    const table = useReactTable({
        data: props.data,
        columns: props.columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        debugTable: true,
        state: {
            sorting,
        },
    })

    return (
        <>
            <div className={styles.tableWrapper}>
                <table className={styles.table}>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    {...{
                                                        onClick:
                                                            header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: <i className="icon-arrow-up" />,
                                                        desc: <i className="icon-arrow-down" />,
                                                    }[header.column.getIsSorted() as string] ??
                                                        null}
                                                </div>
                                            )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className={styles.pagination}>
                <div className={styles.pagesAmount}>
                    <p>Rows per page:</p>
                    <Select
                        options={['10', '20', '30', '40', '50']}
                        value={`${table.getState().pagination.pageSize}`}
                        onChange={newValue => {
                            table.setPageSize(Number(newValue))
                        }}
                        renderOption={option => <>{option}</>}
                        withoutWrapper
                    />
                </div>

                <Pagination
                    pagesAmount={table.getPageCount()}
                    currentPage={table.getState().pagination.pageIndex+1}
                    onClickPage={pageNumber => table.setPageIndex(pageNumber-1)}
                />
                <div className={styles.emptyBlock} />
            </div>
        </>
    )
}
