import { InformationAccordion } from '../components/information-accordion/informationAccordion'
import { PendingTasksAccordion } from '../components/pending-tasks-accordion/pendingTasksAccordion'
import { Layout } from '../components/common/layout/layout'
import { FilesAccordion } from '../components/files-accordion/filesAccordion'
import { useNavigate, useParams } from 'react-router-dom'
import useLogEntry from '../hooks/contexts/useLogEntry'
import { useWindowSize } from '../hooks/useWindowSize'
import Button from '../components/common/button/button'
import useAuth from '../hooks/contexts/useAuth'
import { userHasEditPermission } from '../utils/permission'

const LogEntryDetailPage = () => {
    const { id } = useParams()
    const { user, isFetchingUserRoles } = useAuth()
    const navigate = useNavigate()
    const { isMobileView } = useWindowSize()

    const { logEntriesById, isLoading } = useLogEntry()
    const isLogEntriesIdEmpty = Object.keys(logEntriesById).length === 0

    if (!id) return null

    return (
        <Layout
            pageTitle="Log Entry Detail"
            navigationBackUrl="/log-entries"
            isLoading={isLogEntriesIdEmpty || isLoading || isFetchingUserRoles}
            rightAction={
                <>
                    {logEntriesById[+id] && userHasEditPermission(logEntriesById[+id], user) && (
                        <Button
                            text={isMobileView ? undefined : 'Edit Log Entry'}
                            startIconClassname="icon-edit"
                            variant="blue-bg"
                            size={isMobileView ? 'large' : 'medium'}
                            onClick={e => navigate(`/edit-entry/${id}`)}
                        />
                    )}
                </>
            }
        >
            <InformationAccordion entry={logEntriesById[+id]} />

            <PendingTasksAccordion pendingTask={logEntriesById[+id]?.pendingtask} />

            <FilesAccordion files={logEntriesById[+id]?.filenames || []} />
        </Layout>
    )
}

export default LogEntryDetailPage
