import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { LogEntry } from '../../models/logEntry'
import { differenceBy } from 'lodash'
import { useLogEntryGetAll } from '../../services/logEntry'
import useAuth from './useAuth'

export interface LogEntryContextValue {
    logEntries: LogEntry[]
    logEntriesById: Record<number, LogEntry>
    isLoading: boolean
    addLogEntries: (logEntries: LogEntry[]) => void
    removeLogEntries: (ids: number[]) => void
    refetch: () => void
}

export const LogEntryContext = createContext<LogEntryContextValue>({
    logEntries: [],
    logEntriesById: {},
    isLoading: false,
    addLogEntries: _ => {},
    removeLogEntries: _ => {},
    refetch: () => {},
})

export const LogEntryProvider: FC<{
    children?: ReactNode
}> = props => {
    const { user } = useAuth()
    const [logEntries, setLogEntries] = useState<LogEntry[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [logEntriesById, setLogEntriesById] = useState<Record<string, LogEntry>>({})

    const { isLoading: isFetching, refetch } = useLogEntryGetAll({
        onSuccess: data => {
            setLogEntries(
                data.map((d: LogEntry, index: number) => ({
                    ...d,
                    internalId: index,
                }))
            )
        },
        disabled: !user,
    })

    const addLogEntries = (newLogEntries: LogEntry[]) =>
        setLogEntries([
            ...differenceBy(logEntries, newLogEntries, (c: LogEntry) => c.id),
            ...newLogEntries,
        ])

    const removeLogEntries = (ids: number[]) => {
        setLogEntries(logEntries.filter(entry => !ids.includes(entry.internalId)))
    }

    useEffect(() => {
        if (logEntries) {
            setIsLoading(true)
            const logEntryMap: Record<string, LogEntry> = {}
            logEntries.forEach(entry => {
                if (entry.id) {
                    logEntryMap[entry.internalId] = entry
                }
            })
            setLogEntriesById(logEntryMap)
        }
        setIsLoading(false)
    }, [logEntries])

    return (
        <LogEntryContext.Provider
            value={{
                logEntries,
                logEntriesById,
                isLoading: isFetching || isLoading,
                addLogEntries,
                removeLogEntries,
                refetch,
            }}
        >
            {props.children}
        </LogEntryContext.Provider>
    )
}

export const useLogEntry = (): LogEntryContextValue => useContext(LogEntryContext)

export default useLogEntry
