import { useEffect, useState } from 'react'
import { DesktopLogin } from '../components/desktop-login/desktopLogin'
import { useLocation, useNavigate } from 'react-router'
import { useMsal } from '@azure/msal-react'
import { useWindowSize } from '../hooks/useWindowSize'
import useAuth from '../hooks/contexts/useAuth'
import MobileLogin from '../components/mobile-login/mobileLogin'
import { loginRequest } from '../utils/authConfig'

const LoginPage = () => {
    const { instance } = useMsal()
    const navigate = useNavigate()
    const { state } = useLocation()
    const { isMobileView } = useWindowSize()
    const { user, setAuthenticatedAccount, isFetchingUserRoles } = useAuth()

    const [isAuthenticating, setIsAuthenticating] = useState(false)

    useEffect(() => {
        if (user) navigate(state || '/log-entries')
    }, [user, navigate, state, instance])

    useEffect(() => {
        const activeAccount = instance.getActiveAccount()
        if (activeAccount) setAuthenticatedAccount(activeAccount)
    }, [navigate, instance, setAuthenticatedAccount])

    const handleSignIn = async () => {
        setIsAuthenticating(true)
        await instance.loginRedirect(loginRequest)
        setIsAuthenticating(false)
    }

    const isLoading = isFetchingUserRoles || isAuthenticating

    if (isMobileView) return <MobileLogin onClick={handleSignIn} isLoading={isLoading} />

    return <DesktopLogin onClick={handleSignIn} isLoading={isLoading} />
}

export default LoginPage
