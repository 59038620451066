import { StrictMode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { LogEntryProvider } from './hooks/contexts/useLogEntry'
import { ModalProvider } from './hooks/contexts/useModal'
import { AuthProvider } from './hooks/contexts/useAuth'
import {
    EventType,
    PublicClientApplication,
    EventMessage,
    AuthenticationResult,
} from '@azure/msal-browser'
import { msalConfig } from './utils/authConfig'
import { MsalProvider } from '@azure/msal-react'
import { RigsProvider } from './hooks/contexts/useRigs'
import { UsersProvider } from './hooks/contexts/useUsers'
import { render } from 'react-dom'

const root = ReactDOM.createRoot(document.getElementById('root')!)
const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.addEventCallback((event: EventMessage) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload
    ) {
        const payload = event.payload as AuthenticationResult
        const account = payload.account
        msalInstance.setActiveAccount(account)
        // console.log(payload)
    }
})

root.
render(
    <StrictMode>
        <BrowserRouter>
            <MsalProvider instance={msalInstance}>
                <AuthProvider>
                    <ModalProvider>
                        <LogEntryProvider>
                            <RigsProvider>
                                <UsersProvider>
                                    <DndProvider backend={HTML5Backend} key={1}>
                                        <App />
                                    </DndProvider>
                                </UsersProvider>
                            </RigsProvider>
                        </LogEntryProvider>
                    </ModalProvider>
                </AuthProvider>
            </MsalProvider>
        </BrowserRouter>
    </StrictMode>,
    // document.getElementById('root')
)

reportWebVitals()
