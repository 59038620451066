import { Layout } from '../components/common/layout/layout'
import NotFoundCard from '../components/not-found-card/notFoundCard'

const NotFoundPage = () => {
    return (
        <Layout>
            <NotFoundCard />
        </Layout>
    )
}

export default NotFoundPage
