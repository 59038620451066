import { ColumnDef, Row } from '@tanstack/react-table'
import styles from './logEntriesTable.module.css'
import { FC, useMemo, useState } from 'react'
import useAuth from '../../hooks/contexts/useAuth'
import { LogEntry } from '../../models/logEntry'
import { SHIFT_TYPE, TableHeader } from '../../utils/constants'
import { removeFields } from '../../utils/objectFormatter'
import { userHasEditPermission } from '../../utils/permission'
import { formatDateString, htmlHasContent, truncateHTML } from '../../utils/stringFormatter'
import Button from '../common/button/button'
import { EntryOptionsModal } from '../modals/entry-options-modal/entryOptionsModal'
import { Table } from '../common/table/table'

type LogEntriesTableProps = {
    logEntries: LogEntry[]
    columnsSelected: {
        name: TableHeader
        checked: boolean
    }[]
}

const OptionsCell: FC<{ row: Row<LogEntry> }> = props => {
    const { user } = useAuth()
    const [optionsOpened, setOptionsOpened] = useState(false)
    return (
        <div className={styles.options} id={props.row.id}>
            <Button
                variant={'transparent-bg'}
                startIconClassname="icon-more-vertical"
                onClick={e => {
                    e.stopPropagation()
                    setOptionsOpened(true)
                }}
            />
            {optionsOpened && (
                <EntryOptionsModal
                    entryId={+props.row.original.internalId}
                    disableEdition={!userHasEditPermission(props.row.original, user)}
                    onClose={() => setOptionsOpened(false)}
                />
            )}
        </div>
    )
}

const COLUMNS_MAP: Record<string, ColumnDef<LogEntry>> = {
    'Rig Name': {
        header: 'Rig Name',
        accessorKey: 'rigname.name',
    },
    Operators: {
        header: 'Operators',
        accessorKey: 'operators.name',
        enableSorting: false,
        cell: ({ row }) => {
            const hiddenOperators = row.original.operators.length - 2
            const firstOperatorList = `${row.original.operators
                .map(op => op.name)
                .slice(0, 2)
                .join(', ')}${hiddenOperators > 0 ? `, and ${hiddenOperators} more...` : ''}`

            return <>{firstOperatorList}</>
        },
    },
    'Shift Date': {
        header: 'Shift Date',
        accessorFn: row => new Date(row.shiftdate),
        cell: ({ row }) => (
            <div className={styles.date}>
                <p>{formatDateString(row.original.shiftdate)}</p>
                <span
                    className={
                        row.original.shifttype === SHIFT_TYPE.NIGHT ? styles.night : styles.day
                    }
                >
                    {row.original.shifttype === SHIFT_TYPE.NIGHT ? 'Night' : 'Day'}
                </span>
            </div>
        ),
    },
    'Submitted By': {
        header: 'Submitted By',
        accessorKey: 'submittedby.name',
    },
    'Submitted Date': {
        header: 'Submitted Date',
        accessorFn: row => new Date(row.submittedtime),
        cell: ({ row }) => <>{formatDateString(row.original.submittedtime)}</>,
    },
    'Last Modified By': {
        header: 'Last Modified By',
        accessorKey: 'lastmodifiedby.name',
    },
    'Last Modified Date': {
        header: 'Last Modified Date',
        accessorFn: row => new Date(row.lastmodifiedtime),
        cell: ({ row }) => <>{formatDateString(row.original.lastmodifiedtime)}</>,
    },
    'Files Attached': {
        header: 'Files Attached',
        cell: ({ row }) => (
            <div className={styles.files}>
                <i className="icon-paperclip" />
                <span>{row.original.files}</span>
            </div>
        ),
    },
    'Pending Tasks': {
        header: 'Pending Tasks',
        enableSorting: false,
        cell: ({ row }) => {
            const pendingTasks = htmlHasContent(row.original.pendingtask)
                ? truncateHTML(20, row.original.pendingtask)
                : 'No pending tasks'
            return <div className={styles.text}>{pendingTasks}</div>
        },
    },
    'Shift Summary': {
        header: 'Shift Summary',
        enableSorting: false,
        cell: ({ row }) => {
            const shiftSummary = htmlHasContent(row.original.shiftsummary)
                ? truncateHTML(20, row.original.shiftsummary)
                : '<p>No shift summary</p>'
            return (
                <div className={styles.text} dangerouslySetInnerHTML={{ __html: shiftSummary }} />
            )
        },
    },
    Options: {
        header: 'Options',
        cell: ({ row }) => <OptionsCell row={row} />,
    },
}

export const LogEntriesTable: FC<LogEntriesTableProps> = props => {
    const columns = useMemo<ColumnDef<LogEntry>[]>(
        () => props.columnsSelected.filter(c => c.checked).map(c => COLUMNS_MAP[c.name]),
        [props.columnsSelected]
    )

    const mappedData: LogEntry[] = props.logEntries.map(logEntry => ({
        ...removeFields(logEntry, ['filenames', 'id']),
        files: logEntry.filenames?.length || 0,
    }))

    return <Table data={mappedData} columns={columns} />
}
