import API_PATHS from '../paths'
import { usePost } from '../hooks/usePost'
import { UpdateUser } from '../models/updateUser'
import { CreateUser } from '../models/createUser'

export const useAdminEntryUpdate = (handlers?: {onSuccess?: () => void; onError?: () => void})=> {
    return (usePost<UpdateUser,unknown>({
        ...handlers,
        relativePath: API_PATHS.UpdateUsers,
        toastMessage:{
            error: 'Unable to update user role.',
            success: 'User role has been updated.'
        }
    })
    )
}

export const useAdminEntryCreate = (handlers?: { onSuccess?: () => void; onError?: () => void }) => {
    return usePost<CreateUser, unknown>({
        ...handlers,
        relativePath: API_PATHS.CreateUsers,
        toastMessage: {
            error: 'Unable to create user role.',
            success: 'User role has been created.',
        },
    })
}