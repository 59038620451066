import { FC } from 'react'
import useModal from '../../../hooks/contexts/useModal'
import Button from '../../common/button/button'
import { ContextModal } from '../../common/context-modal/contextModal'
import styles from './deleteFileModal.module.css'

export const DELETE_FILE_MODAL_KEY = (fileName: string) => `delete-file-modal-${fileName}`

type DeleteFileModalProps = {
    fileName: string
    onDelete: () => void
}

export const DeleteFileModal: FC<DeleteFileModalProps> = props => {
    const { toggleModal } = useModal()

    return (
        <ContextModal modalKey={DELETE_FILE_MODAL_KEY(props.fileName)} grayOverlay>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <h4>Delete File</h4>

                <span>Are you sure you want to delete this file?</span>

                <footer>
                    <Button
                        variant={'blue-bg'}
                        text="Cancel"
                        onClick={() => toggleModal(DELETE_FILE_MODAL_KEY(props.fileName))}
                    />
                    <Button
                        variant={'gray-bg'}
                        text="Delete"
                        onClick={() => {
                            props.onDelete()
                            toggleModal(DELETE_FILE_MODAL_KEY(props.fileName))
                        }}
                    />
                </footer>
            </div>
        </ContextModal>
    )
}
