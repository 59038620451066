import React, { FC } from 'react'
import styles from './checkbox.module.css'

type CheckboxProps = {
    isChecked: boolean
    onChange?: () => void
}

export const Checkbox: FC<CheckboxProps> = props => {
    return (
        <label className={props.isChecked ? styles.checked : styles.unchecked}>
            <input type="checkbox" checked={props.isChecked} onChange={props.onChange} />
            <span className={styles.checkmark}>
                <i className="icon-check" />
            </span>
        </label>
    )
}
