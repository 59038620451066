import classNames from 'classnames'
import { useWindowSize } from '../../hooks/useWindowSize'
import {
    TABLE_COLUMN_SELECTOR_MODAL_KEY,
    TableColumnsSelectorModal,
} from '../modals/table-column-selector-modal/tableColumnSelectorModal'
import {
    TABLE_SORT_MODAL_KEY,
    TableSortByModal,
} from '../modals/table-sort-by-modal/tableSortByModal'
import styles from './tableFilters.module.css'
import { Dispatch, FC, SetStateAction } from 'react'
import useModal from '../../hooks/contexts/useModal'
import { DEFAULT_SORT, TableHeader } from '../../utils/constants'

type TableFiltersProps = {
    columnsSelected: {
        name: TableHeader
        checked: boolean
    }[]
    sortBy: { options: TableHeader[]; selected: string }
    setSortedBy: Dispatch<SetStateAction<TableHeader>>
    onChangeInput: Dispatch<SetStateAction<string>>
    onSelectColumn: (columnClicked: string) => void
    onSwapColumns: (index1: number, index2: number) => void
    onResetColumns: () => void
}

export const TableFilters: FC<TableFiltersProps> = props => {
    const { modalKeys } = useModal()
    const { toggleModal } = useModal()
    const { isMobileView } = useWindowSize()

    const isSorting = props.sortBy.selected !== DEFAULT_SORT

    return (
        <div className={styles.container}>
            <h3>Log Entries</h3>
            <div className={styles.filters}>
                <div className={styles.search}>
                    <input
                        placeholder="Search"
                        onChange={e => props.onChangeInput(e.target.value)}
                    />
                    <i className="icon-search" />
                </div>
                <div className={styles.rightSide}>
                    {isMobileView && (
                        <div
                            className={classNames(
                                styles.modalButton,
                                modalKeys.includes(TABLE_SORT_MODAL_KEY) ? styles.focusSettings : ''
                            )}
                        >
                            <div
                                className={styles.settings}
                                onClick={() => toggleModal(TABLE_SORT_MODAL_KEY)}
                            >
                                <i className="icon-sort-amount-down" />
                                <i
                                    className={classNames('icon-chevron-down', styles.settingIcon)}
                                />
                                {isSorting && <span className={styles.sortDot} />}
                            </div>
                            <TableSortByModal
                                {...props.sortBy}
                                onChange={selected => props.setSortedBy(selected as TableHeader)}
                            />
                        </div>
                    )}

                    <div
                        className={classNames(
                            styles.modalButton,
                            modalKeys.includes(TABLE_COLUMN_SELECTOR_MODAL_KEY)
                                ? styles.focusSettings
                                : ''
                        )}
                    >
                        <div
                            className={styles.settings}
                            onClick={() => toggleModal(TABLE_COLUMN_SELECTOR_MODAL_KEY)}
                        >
                            <i className="icon-settings" />
                            <h6>Settings</h6>
                            <i className={classNames('icon-chevron-down', styles.settingIcon)} />
                        </div>
                        <TableColumnsSelectorModal
                            columns={props.columnsSelected}
                            onChange={props.onSelectColumn}
                            onReset={props.onResetColumns}
                            onSwapRows={props.onSwapColumns}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableFilters
