import { FC, useMemo } from 'react'
import { LogEntry } from '../../models/logEntry'
import styles from './informationAccordion.module.css'
import { capitalizeOrReplace, formatDateString, htmlHasContent } from '../../utils/stringFormatter'
import { Accordion } from '../common/accordion/accordion'
import classNames from 'classnames'

type InformationAccordionProps = {
    entry: LogEntry
}

export const InformationAccordion: FC<InformationAccordionProps> = props => {
    const fieldsDisplayed: { label: string; value?: string }[] = useMemo(
        () => [
            { label: 'Rig name', value: props.entry.rigname?.name },
            { label: 'Operators', value: props.entry.operators.map(op => op.name).join(', ') },
            { label: 'Shift Date', value: formatDateString(props.entry.shiftdate) },
            { label: 'Submitted By', value: props.entry.submittedby?.name },
            { label: 'Last updated by', value: props.entry.lastmodifiedby?.name },
            { label: 'Last Modified', value: formatDateString(props.entry.lastmodifiedtime) },
        ],
        [
            props.entry.lastmodifiedby,
            props.entry.lastmodifiedtime,
            props.entry.operators,
            props.entry.rigname,
            props.entry.shiftdate,
            props.entry.submittedby,
        ]
    )

    const summaryHasContent = htmlHasContent(props.entry.shiftsummary)
    const shiftSummary = summaryHasContent ? props.entry.shiftsummary : '<p>No shift summary</p>'

    return (
        <div className={styles.container}>
            <Accordion
                summary={isExpanded => (
                    <div className={styles.summary}>
                        <div className={styles.title}>
                            <i className="icon-info" />
                            <h3>Information</h3>
                        </div>
                        <button>{isExpanded ? <span>-</span> : <span>+</span>}</button>
                    </div>
                )}
                content={
                    <>
                        <div className={styles.information}>
                            {fieldsDisplayed.map((field, index) => (
                                <div
                                    className={styles.field}
                                    key={`information-accordion-${field}-${index}`}
                                >
                                    <h4>{field.label}</h4>
                                    <p>{capitalizeOrReplace('-', field.value)}</p>
                                </div>
                            ))}
                        </div>
                        <div className={styles.shift}>
                            <div className={styles.title}>
                                <i className="icon-file-text" />
                                <h3>Shift Summary</h3>
                            </div>
                            <div
                                className={classNames(styles.text, 'public-DraftEditor-content')}
                                dangerouslySetInnerHTML={{ __html: shiftSummary }}
                            />
                        </div>
                    </>
                }
                defaultExpanded
            />
        </div>
    )
}
